import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { NavItemContainer, NavText, BigContainer } from './NavItem.elements';
import NavbarOptions from '../NavbarOptions';

import { ExpandMore } from '../../assets/icons/index';

type NavItemProps = {
  text: string;
  redirectTo?: string;
  isActive: boolean;
  dropdown?: boolean;
  paths?: any;
  background?: any;
  setBackground?: any;
};

const NavItem: React.FC<NavItemProps> = ({
  text,
  redirectTo,
  isActive,
  dropdown,
  paths,
  background,
  setBackground,
}: NavItemProps) => {
  const [dropdownOpen, setDropdownopen] = useState(false);

  return (
    <>
      {dropdown ? (
        <>
          <BigContainer>
            <NavItemContainer
              onClick={() => {
                setDropdownopen(!dropdownOpen);
                setBackground(!background);
              }}
              dropdown={dropdown}
            >
              <NavText selected={isActive}>{text}</NavText>
              <ExpandMore style={{ marginLeft: 15 }} />
            </NavItemContainer>
            {dropdownOpen ? (
              <NavbarOptions
                closeModal={() => {
                  setDropdownopen(false);
                  setBackground(false);
                }}
                options={paths}
              ></NavbarOptions>
            ) : null}
          </BigContainer>
        </>
      ) : (
        <Link
          to={redirectTo ? redirectTo : '/'}
          style={{ textDecoration: 'none' }}
        >
          <NavItemContainer>
            <NavText selected={isActive}>{text}</NavText>
          </NavItemContainer>
        </Link>
      )}
    </>
  );
};

export default NavItem;
