import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import theme from '../../assets/global/index';

import {
  Container,
  Title,
  InputsContainer,
  Subtitle,
  AddContainer,
  AddTitle,
  ButtonsContainer,
  DenyModalButton,
  CancelModalButton,
  AdditionOptionsContainer,
  ProfileImageContainer,
  ProfileImage,
  ProfileImageEditButton,
  AddPhotoContainer,
  AddPhotoIconContainer,
  AdditionCardContainer,
  LittleModalWrapper,
} from './AddInsuranceCard.elements';
import { InputComponent, Divisor, AdditionOptions } from '../index';
import { Edit, AddPhoto, CircleAdd, Alert } from '../../assets/icons';
import { AdditionCard } from '../AdditionCard';
import {
  ErrorContainer,
  ErrorMessage,
  LoaderContainer,
} from '../AddServicesCard/AddServicesCard.elements';
import { ThreeDots } from 'react-loader-spinner';

type AddInsusranceCardProps = {
  type?: string;
  leftButtonFunction: any;
  rightButtonFunction: any;
  insuranceId?: string;
};

interface InsuranceProps {
  id: string;
  name: string;
  photo_address: string;
  updated_image_file?: File;
}

interface ServiceProps {
  id: string;
  name: string;
}

interface InsuranceServiceProps {
  id: string;
  service: ServiceProps;
}

const AddInsuranceCard: React.FC<AddInsusranceCardProps> = ({
  type,
  leftButtonFunction,
  rightButtonFunction,
  insuranceId,
}) => {
  const [openLittleModal, setOpenLittleModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(type == 'edit' ? true : false);
  const [insurancesServices, setInsurancesServices] = useState(() => {
    return [] as InsuranceServiceProps[];
  });
  const [removedInsurancesServices, setRemovedInsurancesServices] = useState(
    () => {
      return [] as InsuranceServiceProps[];
    },
  );
  const [addedServices, setAddedServices] = useState(() => {
    return [] as ServiceProps[];
  });
  const [insurance, setInsurance] = useState(() => {
    return {} as InsuranceProps;
  });
  const [services, setServices] = useState(() => {
    return [] as ServiceProps[];
  });

  const loadInsurancesServices = useCallback(async () => {
    const res = await api.get(
      `/insurances-services?insuranceId=${insuranceId}`,
    );

    if (res && res.data) {
      const data = res.data.map((insuranceService: InsuranceServiceProps) => ({
        id: insuranceService.id,
        service: {
          id: insuranceService.service.id,
          name: insuranceService.service.name,
        },
      }));
      setInsurancesServices(data);
    }
  }, [insuranceId]);

  const loadInsurance = useCallback(async () => {
    const res = await api.get(`/insurances/${insuranceId}`);

    if (res && res.data) {
      const data = {
        id: res.data.id,
        name: res.data.name,
        photo_address: res.data.photo_address,
        updated_image_file: undefined,
      };

      setInsurance(data);
      await loadInsurancesServices();
      setLoading(false);
    }
  }, [insuranceId, loadInsurancesServices]);

  const updateInsuranceImage = async (id?: string) => {
    if (
      insurance.updated_image_file != null &&
      insurance.updated_image_file != undefined
    ) {
      const data = new FormData();
      data.append('files', insurance.updated_image_file);

      await api.post(`/files/insurances/${id ?? insuranceId}`, data);
    }
  };

  const updateInsurance = async () => {
    await updateInsuranceImage();

    await api.put(`/insurances/${insuranceId}`, {
      name: insurance.name,
    });

    if (removedInsurancesServices.length) {
      removedInsurancesServices.forEach(async (is: InsuranceServiceProps) => {
        await removeInsuranceService(is.id);
      });
    }
    if (addedServices.length) {
      addedServices.forEach(async (serv: ServiceProps) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await addInsuranceService(insuranceId!, serv.id);
      });
    }
  };

  const addInsuranceService = async (insId: string, servId: string) => {
    await api.post(`/insurances-services`, {
      insurance_id: insId,
      service_id: servId,
    });
  };

  const removeInsuranceService = async (id: string) => {
    await api.delete(`/insurances-services/${id}`);
  };

  const addService = async (serv: ServiceProps) => {
    setInsurancesServices([...insurancesServices, { id: '', service: serv }]);
    setAddedServices([...addedServices, serv]);
  };

  const removeService = async (is: InsuranceServiceProps) => {
    const updatedInsurancesServices = insurancesServices.filter(
      (ins: InsuranceServiceProps) => ins.service.id != is.service.id,
    );

    const updatedAddedServices = addedServices.filter(
      (serv: ServiceProps) => serv.id != is.service.id,
    );
    setInsurancesServices(updatedInsurancesServices);
    setAddedServices(updatedAddedServices);

    if (is.id !== '') {
      setRemovedInsurancesServices([...removedInsurancesServices, is]);
    }
  };

  const createInsurance = async () => {
    const res = await api.post(`/insurances`, {
      name: insurance.name,
    });

    if (res.data) {
      await updateInsuranceImage(res.data.id);
      if (addedServices.length) {
        const notRepeted = addedServices.filter(
          (el: ServiceProps, i: number) => addedServices.indexOf(el) === i,
        );
        notRepeted.forEach(async (serv: ServiceProps) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await addInsuranceService(res.data.id, serv.id);
        });
      }
    }
  };

  const loadServices = useCallback(async () => {
    const res = await api.get(`/services?text=${searchText}`);
    if (res && res.data) {
      const data = res.data.map((serv: ServiceProps) => ({
        id: serv.id,
        name: serv.name,
      }));
      const filteredData: ServiceProps[] = [];

      data.forEach((serv: ServiceProps) => {
        let alreadyExists = false;

        insurancesServices.forEach((ins: InsuranceServiceProps) => {
          if (ins.service.id === serv.id) {
            alreadyExists = true;
          }
        });
        if (!alreadyExists) {
          filteredData.push(serv);
        }
      });

      setServices(filteredData);
    }
  }, [insurancesServices, searchText]);

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const reader = new FileReader();
      const target = e.target.files[0];
      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = () => {
        setInsurance({
          ...insurance,
          photo_address: String(reader?.result),
          updated_image_file: target,
        });
      };
    }
  };

  const saveChanges = async () => {
    if (insurance?.name?.length) {
      setLoading(true);
      if (type == 'edit') {
        await updateInsurance();
      } else {
        await createInsurance();
      }

      setTimeout(() => {
        rightButtonFunction();
        setLoading(false);
      }, 1000);
    }
  };

  const activeInputImage = () => {
    document.getElementById('image-input')?.click();
  };

  const inputImage = () => (
    <input
      id="image-input"
      type="file"
      onChange={handleImageChange}
      style={{ display: 'none' }}
    />
  );

  useEffect(() => {
    if (type == 'edit') {
      loadInsurance();
    }
  }, [loadInsurance, type]);

  useEffect(() => {
    loadServices();
  }, [insurancesServices, loadServices, searchText]);

  return (
    <>
      <Container>
        {type == 'edit' ? (
          <Title>Editar convênio</Title>
        ) : (
          <Title>Adicionar convênio</Title>
        )}
        {!loading ? (
          <>
            <ProfileImageContainer>
              {insurance?.photo_address != null ? (
                <>
                  <ProfileImage src={insurance?.photo_address} />
                  <ProfileImageEditButton onClick={activeInputImage}>
                    <Edit />
                    {inputImage()}
                  </ProfileImageEditButton>
                </>
              ) : (
                <AddPhotoContainer onClick={activeInputImage}>
                  <AddPhotoIconContainer>
                    <AddPhoto />
                    {inputImage()}
                  </AddPhotoIconContainer>
                </AddPhotoContainer>
              )}
            </ProfileImageContainer>

            <InputsContainer>
              <InputComponent
                placeHolder="Nome"
                fillInput={(text: string) =>
                  setInsurance({ ...insurance, name: text })
                }
                height="5.5rem"
                valueToShow={insurance?.name}
              />
              {!insurance?.name?.length && (
                <ErrorContainer>
                  <Alert />
                  <ErrorMessage>
                    {'É necessário que o convênio tenha um nome'}
                  </ErrorMessage>
                </ErrorContainer>
              )}
            </InputsContainer>

            <Divisor />
            <Subtitle>Serviços que contém esse convênio:</Subtitle>
            <AdditionOptionsContainer>
              {insurancesServices.map((insuranceService, index) => (
                <AdditionOptions
                  key={index}
                  title={insuranceService.service.name}
                  deleteFunction={() => removeService(insuranceService)}
                />
              ))}
            </AdditionOptionsContainer>
            <LittleModalWrapper>
              <AddContainer
                onClick={() => setOpenLittleModal(!openLittleModal)}
              >
                <CircleAdd />
                <AddTitle>Adicionar serviço</AddTitle>
              </AddContainer>
              {openLittleModal && (
                <AdditionCardContainer>
                  <AdditionCard
                    options={services}
                    changeInput={setSearchText}
                    placeholder="Buscar serviço"
                    onClickAction={addService}
                  />
                </AdditionCardContainer>
              )}
            </LittleModalWrapper>
            <ButtonsContainer>
              <CancelModalButton onClick={leftButtonFunction}>
                Cancelar
              </CancelModalButton>
              <DenyModalButton onClick={saveChanges}>
                Salvar alterações
              </DenyModalButton>
            </ButtonsContainer>
          </>
        ) : (
          <LoaderContainer>
            <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
          </LoaderContainer>
        )}
      </Container>
    </>
  );
};
export default AddInsuranceCard;
