import React, { useState, useEffect, useCallback } from 'react';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../../assets/global/index';
import { Card, Divisor, Modal, AddServicesCard } from '../../../components';
import CardItem from '../components/CardItem/CardItem';
import HeaderSearch from '../components/HeaderSearch/HeaderSearch';
import api from '../../../services/api';
import {
  CardDivisorContainer,
  CardsContainer,
  LoaderContainer,
  NoFoundMessage,
} from '../ServicesOrInsurances.elements';
import { orderItems, updateItemIndex } from '../utilsFunc';

interface ServicesProps {
  id: string;
  name: string;
  phone: string;
  index_order: number;
  fixed_top: boolean;
  descriptionTexts: {
    streetAddress: string;
    region: string;
    cep: string;
  };
  services_insurances: any[];
}

const Services: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [modal, setModal] = useState(false);
  const [services, setServices] = useState<ServicesProps[]>(() => {
    return [] as ServicesProps[];
  });

  const loadServices = useCallback(async () => {
    const res = await api.get(
      `/services?showInsurances=true&text=${searchText}`,
    );
    if (res && res.data) {
      const data = res.data.map((info: any) => {
        const servicesInsurances = info?.services_insurances.map(
          (insService: any) => ({
            id: insService?.insurance?.id,
            title: insService?.insurance?.name,
            image: insService?.insurance?.photo_address,
          }),
        );
        return {
          id: info.id,
          name: info.name,
          phone: info.phone,
          index_order: info.index_order,
          descriptionTexts: {
            streetAddress: info.street_address,
            region: info.region,
            cep: info.cep,
          },
          fixed_top: info.fixed_top,
          services_insurances: [...servicesInsurances],
        };
      });
      setServices(data.sort(orderItems));
      setLoading(false);
    }
  }, [searchText]);

  const orderItemIndex = async (
    itemId: string,
    orderUp: boolean,
    endOfList: boolean,
  ) => {
    services.forEach(
      async (value: ServicesProps, index: number, values: ServicesProps[]) => {
        if (value.id === itemId) {
          const otherItem = endOfList
            ? values[values.length - 1]
            : orderUp
            ? values[index - 1]
            : values[index + 1];
          if (otherItem !== null && otherItem !== undefined) {
            if (!value?.fixed_top && !otherItem.fixed_top) {
              await updateItemIndex(true, itemId, otherItem.index_order);
              await updateItemIndex(true, otherItem.id, value.index_order);
              await loadServices();
            }
          }
        }
      },
    );
  };

  const fixedOnTop = async (itemId: string) => {
    services.forEach(
      async (value: ServicesProps, index: number, values: ServicesProps[]) => {
        if (value.id === itemId) {
          const otherItem = values[0];

          if (otherItem !== null && otherItem !== undefined) {
            await updateItemIndex(true, itemId, 1, true);
            if (otherItem?.id != itemId) {
              await updateItemIndex(true, otherItem.id, value.index_order);
            }
            await loadServices();
          }
        }
      },
    );
  };

  const deleteService = async (id: string) => {
    await api.delete(`/services/${id}`);
    await loadServices();
  };

  useEffect(() => {
    loadServices();
  }, [loadServices, searchText]);

  return (
    <>
      <CardsContainer>
        <HeaderSearch
          searchBarPlaceholder="Buscar serviços pelo nome"
          buttonTitle="Adicionar novo serviço"
          onChangeText={setSearchText}
          onClickButton={() => setModal(!modal)}
        />
        <Card title="Serviços SOnHe">
          {!loading && !services.length ? (
            <NoFoundMessage>Nenhum serviço encontrado</NoFoundMessage>
          ) : !loading ? (
            services.map((serv: ServicesProps, index: number) => (
              <>
                <CardItem
                  key={index}
                  isService={true}
                  fixedTop={serv.fixed_top}
                  firstItem={index == 0}
                  lastItem={index == services?.length - 1}
                  cardTitle={serv.name}
                  cardSubtitle={serv.phone}
                  cardDescriptionTexts={serv.descriptionTexts}
                  cardListItems={serv.services_insurances}
                  cardListTitle={'Convênios aceitos nesse local'}
                  itemId={serv.id}
                  orderItemFunc={orderItemIndex}
                  fixedItemFunc={fixedOnTop}
                  unpinItemFunc={async () => {
                    await updateItemIndex(true, serv.id, serv.index_order);
                    await loadServices();
                  }}
                  deleteAction={async () => {
                    await deleteService(serv.id);
                    alert('Serviço deletado');
                  }}
                />
                {index + 1 !== services.length && (
                  <CardDivisorContainer>
                    <Divisor width={100} />
                  </CardDivisorContainer>
                )}
              </>
            ))
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
      <Modal showModal={modal} setShowModal={setModal} otherPage>
        <AddServicesCard
          leftButtonFunction={() => setModal(!modal)}
          rightButtonFunction={() => setModal(!modal)}
          type="add"
        />
      </Modal>
    </>
  );
};

export default Services;
