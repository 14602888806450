import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import {
  NavContainer,
  MainContentContainer,
  ListOfItems,
  ListOfIcons,
  IconContainer,
  ExitContainer,
  ExitText,
  GlobalDiv,
} from './Navbar.elements';

import NavItem from '../NavItem';
import NotificationBox from '../NotificationBox';

import { Logo, Search } from '../../assets/icons';

const Navbar: React.FC = () => {
  const { pathname } = useLocation();
  const { signOut } = useAuth();
  const [blackBackground, setBlackBackground] = useState(false);

  const onExit = () => {
    signOut();
    alert('Você saiu do Admin!');
  };

  return (
    <>
      <NavContainer>
        <MainContentContainer>
          <Logo />

          <ListOfItems>
            {/* Navbar options */}
            <NavItem
              text={'Encaminhamentos'}
              redirectTo={'/encaminhamentos'}
              isActive={pathname === '/encaminhamentos'}
            />
            <NavItem
              text={'controle de pacientes'}
              redirectTo={'/controle-de-pacientes'}
              isActive={pathname === '/controle-de-pacientes'}
            />
            <NavItem
              text={'controle de médicos'}
              redirectTo={'/controle-de-médicos'}
              isActive={pathname === '/controle-de-médicos'}
            />
            {/*  lembrar de mudar os paths quando as novas telas forem criadas */}
            <NavItem
              text={'informações cadastrais'}
              isActive={
                pathname === '/serviços-sonhe' ||
                pathname === '/convênios-sonhe' ||
                pathname === '/especialidades'
              }
              dropdown
              paths={[
                { title: 'Serviços SOnHe', path: '/serviços-sonhe' },
                { title: 'Convênios SOnHe', path: '/convênios-sonhe' },
                { title: 'Especialidades SOnHe', path: '/especialidades' },
              ]}
              background={blackBackground}
              setBackground={setBlackBackground}
            />
          </ListOfItems>

          <ListOfIcons>
            {/* Search icon */}
            <IconContainer as={Link} to={'/controle-de-pacientes'}>
              <Search />
            </IconContainer>

            {/* Notifications icon */}
            <IconContainer id={'notification'}>
              <NotificationBox />
            </IconContainer>

            {/* Exit Button */}
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <ExitContainer onClick={onExit}>
                <ExitText>Sair</ExitText>
              </ExitContainer>
            </Link>
          </ListOfIcons>
        </MainContentContainer>
      </NavContainer>
      <GlobalDiv
        style={blackBackground ? { display: 'inline' } : { display: 'none' }}
      ></GlobalDiv>
    </>
  );
};

export default Navbar;
