import styled from 'styled-components';

export const CardsContainer = styled.div`
  max-width: 56%;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-areas:
    'card1'
    'card2';
  grid-gap: 3.2rem;
`;

export const CardDoctor = styled.div`
  width: 100%;
  display: flex;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PatientOf = styled.p`
  font: normal normal normal 1.8rem ' Roboto ', sans-serif;
  color: ${props => props.theme.color.blue};
`;

export const DoctorName = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
`;

export const PhotoDoctorContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 50%;
  background: url('https://i.imgur.com/YEZqrgT.png') no-repeat;
  background-size: cover;
`;

export const EmptyList = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  position: relative;
  text-align: center;
  margin: 12rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
