import styled from 'styled-components';

export const ReferralsContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  align-self: flex-start;
  background-color: none;
  width: 100%;
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
  transition: background-color 0.1s ease-in;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PatientName = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};
`;

export const PatientNumber = styled.p`
  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.black};
  margin-left: 4px;
`;

export const PatientForwardedBy = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
  margin-left: 4px;
`;

export const ForwardingDate = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
  margin-left: 4px;
`;

export const ForwardingContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 0.7rem;
`;

export const ForwardedText = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;

export const PatientInfos = styled.div`
  display: flex;
  align-items: baseline;
`;

export const CallButton = styled.div`
  width: 187px;
  height: 50px;
  background-color: ${props => props.theme.color.black};
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
export {};
