import React, { useCallback, useEffect, useState } from 'react';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../../assets/global/index';
import { Card, Divisor, Modal } from '../../../components';

import AddInsuranceCard from '../../../components/AddInsuranceCard';
import api from '../../../services/api';
import CardItem from '../components/CardItem/CardItem';
import HeaderSearch from '../components/HeaderSearch/HeaderSearch';
import {
  CardDivisorContainer,
  CardsContainer,
  LoaderContainer,
  NoFoundMessage,
} from '../ServicesOrInsurances.elements';
import { orderItems, updateItemIndex } from '../utilsFunc';

interface InsuranceProps {
  id: string;
  name: string;
  photo_address: string;
  index_order: number;
  fixed_top: boolean;
  insurances_services: any[];
}

const Insurances: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [modal, setModal] = useState(false);
  const [insurances, setInsurances] = useState<InsuranceProps[]>(() => {
    return [] as InsuranceProps[];
  });

  const loadInsurances = useCallback(async () => {
    const res = await api.get(
      `/insurances?showServices=true&text=${searchText}`,
    );
    if (res && res.data) {
      const data = res.data.map((info: any) => {
        const insurancesServices = info?.insurances_services.map(
          (insService: any) => ({
            id: insService?.service?.id,
            title: `${insService?.service?.name}, ${insService?.service?.street_address}`,
          }),
        );
        return {
          id: info.id,
          name: info.name,
          phone: info.phone,
          photo_address: info.photo_address,
          index_order: info.index_order,
          fixed_top: info.fixed_top,
          insurances_services: [...insurancesServices],
        };
      });
      setInsurances(data.sort(orderItems));
      setLoading(false);
    }
  }, [searchText]);

  const orderItemIndex = async (
    itemId: string,
    orderUp: boolean,
    endOfList: boolean,
  ) => {
    insurances.forEach(
      async (
        value: InsuranceProps,
        index: number,
        values: InsuranceProps[],
      ) => {
        if (value.id === itemId) {
          const otherItem = endOfList
            ? values[values.length - 1]
            : orderUp
            ? values[index - 1]
            : values[index + 1];
          if (otherItem !== null && otherItem !== undefined) {
            if (!value?.fixed_top && !otherItem.fixed_top) {
              await updateItemIndex(false, itemId, otherItem.index_order);
              await updateItemIndex(false, otherItem.id, value.index_order);
              await loadInsurances();
            }
          }
        }
      },
    );
  };

  const fixedOnTop = async (itemId: string) => {
    insurances.forEach(
      async (
        value: InsuranceProps,
        index: number,
        values: InsuranceProps[],
      ) => {
        if (value.id === itemId) {
          const otherItem = values[0];

          if (otherItem !== null && otherItem !== undefined) {
            await updateItemIndex(false, itemId, 1, true);
            if (otherItem?.id != itemId) {
              await updateItemIndex(false, otherItem.id, value.index_order);
            }
            await loadInsurances();
          }
        }
      },
    );
  };

  const deleteInsurance = async (id: string) => {
    await api.delete(`/insurances/${id}`);
    await loadInsurances();
  };

  useEffect(() => {
    loadInsurances();
  }, [loadInsurances, searchText]);

  return (
    <>
      <CardsContainer>
        <HeaderSearch
          searchBarPlaceholder="Buscar convênios pelo nome"
          buttonTitle="Adicionar novo convênio"
          onChangeText={setSearchText}
          onClickButton={() => setModal(!modal)}
        />
        <Card title="Convênios SOnHe">
          {!loading && !insurances.length ? (
            <NoFoundMessage>Nenhum convênio encontrado</NoFoundMessage>
          ) : !loading ? (
            insurances.map((ins: InsuranceProps, index: number) => (
              <>
                <CardItem
                  key={ins.id}
                  isService={false}
                  fixedTop={ins.fixed_top}
                  firstItem={index == 0}
                  lastItem={index == insurances?.length - 1}
                  cardTitle={ins.name}
                  cardTitleImage={ins.photo_address}
                  cardListTitle={'Serviços relacionados'}
                  cardListItems={ins.insurances_services}
                  itemId={ins.id}
                  orderItemFunc={orderItemIndex}
                  fixedItemFunc={fixedOnTop}
                  reloadItems={loadInsurances}
                  unpinItemFunc={async () => {
                    await updateItemIndex(false, ins.id, ins.index_order);
                    await loadInsurances();
                  }}
                  deleteAction={async () => {
                    await deleteInsurance(ins.id);
                    alert('Convênio deletado');
                  }}
                />
                {index + 1 !== insurances.length && (
                  <CardDivisorContainer>
                    <Divisor width={100} />
                  </CardDivisorContainer>
                )}
              </>
            ))
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
      <Modal showModal={modal} setShowModal={setModal}>
        <AddInsuranceCard
          leftButtonFunction={() => setModal(!modal)}
          rightButtonFunction={async () => {
            setModal(!modal);
            await loadInsurances();
          }}
          type="add"
        />
      </Modal>
    </>
  );
};

export default Insurances;
