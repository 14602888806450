import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSocket } from '../../hooks/socket';

import {
  Container,
  DropDownMenu,
  NotificationTitle,
  Wrapper,
} from './NotificationBox.elements';

import { Notification } from '../../assets/icons';
import NotificationItem from './NotificationItem';
import Divisor from '../Divisor';
import api from '../../services/api';

import { countTimeSince } from '../../utils/countTimeSince';

const defaultPhoto =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7WkdZRcEzdm6UdF4r7MMXzAxXWBnyMCxMEQ&usqp=CAU';

export interface NotificationProps {
  id?: string;
  title: string;
  photo: string;
  description: string;
  notification_type?: string;
  time: string;
  redirectTo?: () => void;
}

const NotificationBox: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { socket } = useSocket();
  const history = useHistory();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [notifications, setNotifications] = useState<NotificationProps[]>(
    () => {
      return [] as NotificationProps[];
    },
  );
  const page = 0;
  const perPage = 10;

  const loadNotifications = async () => {
    const res = await api.get(
      `admin-notifications/?page=${page}&perPage=${perPage}`,
    );

    const data: NotificationProps[] = res.data.map((notification: any) => ({
      id: notification.id,
      title: notification.title,
      photo: notification.image_address,
      description: notification.content,
      time: notification.created_at,
    }));
    setNotifications(data);
  };

  const setNewIdNotification = (notificationId: string) => {
    if (notificationId != undefined) {
      localStorage.setItem(
        '@SOnHeAdmin:notification',
        JSON.stringify(notificationId),
      );
    }
  };

  const getNotificationIcon = (): SVGPathElement | null | undefined => {
    const notIcon = document.querySelector('#notification') as HTMLDivElement;
    if (notIcon != null && notIcon.querySelector('svg') != null) {
      const icon = notIcon.querySelector('svg');
      if (icon != null) {
        const pathSvg = icon.querySelector('path');
        return pathSvg;
      }
    } else {
      return null;
    }
  };
  const newNotification = () => {
    const notIcon = getNotificationIcon();

    if (notIcon != null && notIcon != undefined) {
      if (open == false) {
        notIcon.style.fill = '#EB5757';
      } else {
        notIcon.style.fill = 'white';
      }
    }
  };

  const checkNotification = () => {
    let id = localStorage.getItem('@SOnHeAdmin:notification');
    if (id != null) {
      id = JSON.parse(id);
      if (notifications[0]?.id != undefined) {
        if (id !== notifications[0]?.id) {
          newNotification();
        }
      }
    }
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    checkNotification();
  }, [notifications[0]?.id]);

  useEffect(() => {
    if (socket) {
      socket.on('notifyAdmin', (data: any) => {
        if (
          data.notification_type == 'create_user' &&
          data.recipient_id == null
        ) {
          setNotifications([
            {
              id: data.id,
              title: data.title,
              description: data.content,
              photo: data.image_address,
              time: data.created_at,
            },
            ...notifications,
          ]);
          setNewIdNotification(data.id);
          newNotification();
          if (notifications.length >= perPage - 1) {
            loadNotifications();
          }
        }
      });
    }
  }, [notifications, socket]);

  const handler = (event: { target: any }) => {
    const reference = menuRef.current;
    const notIcon = getNotificationIcon();
    if (reference && !reference.contains(event.target)) {
      setOpen(false);
      if (notIcon != null && notIcon != undefined) {
        notIcon.style.fill = 'white';
      }
    } else {
      const notificationIcon = document.getElementById('notification');
      if (notificationIcon != reference) {
        if (notIcon != null && notIcon != undefined && open == false) {
          notIcon.style.fill = 'white';
          if (notifications[0]?.id != undefined) {
            setNewIdNotification(notifications[0]?.id);
          }
        }

        setOpen(!open);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  });

  const redirectToDoctors = () => {
    history.push('/controle-de-médicos');
  };
  return (
    <>
      <Container ref={menuRef}>
        <Notification />
        {open && (
          <DropDownMenu>
            <Wrapper>
              <NotificationTitle> Notificações </NotificationTitle>
              {notifications?.map(({ id, photo, title, description, time }) => (
                <>
                  <NotificationItem
                    key={id}
                    photo={
                      photo != '' && photo != undefined && photo != null
                        ? photo
                        : defaultPhoto
                    }
                    title={title}
                    description={description}
                    time={countTimeSince(time)}
                    redirectTo={redirectToDoctors}
                  />
                  <Divisor />
                </>
              ))}
            </Wrapper>
          </DropDownMenu>
        )}
      </Container>
    </>
  );
};

export default NotificationBox;
