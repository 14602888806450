import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Title } from './DropdownOptions.elements';
import { ArrowRight } from '../../../assets/icons';

type DropdownOptionsProps = {
  title: string;
  path: string;
  closeModal: () => void;
};

const DropdownOptions: React.FC<DropdownOptionsProps> = ({
  title,
  path,
  closeModal,
}) => {
  return (
    <Link style={{ textDecoration: 'none' }} to={path}>
      <Container onClick={closeModal}>
        <Title>{title}</Title>
        <ArrowRight />
      </Container>
    </Link>
  );
};

export default DropdownOptions;
