import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface DoctorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  DImage?: string;
}

export const ThreeDotsContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-top: -1%;
`;

export const DenyButton = styled.button`
  width: 19rem;
  height: 5rem;
  color: ${props => props.theme.color.red};
  background-color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => props.theme.color.red};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;

export const DenyButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 2.1rem;
`;

export const ConfirmButton = styled.button`
  width: 19rem;
  height: 5rem;
  color: ${props => props.theme.color.green};
  background-color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => props.theme.color.green};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.color.green};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;

export const ConfirmButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const ActionContainer = styled.div``;

export const DropDownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 21rem;
  overflow: hidden;
  background-color: ${props => props.theme.color.white};
  border: 1px solid #adadad;
  border-radius: 0.5rem;
  margin-top: 25px;
  margin-left: 5px;
`;

export const DoctorFirstPart = styled.div`
  display: flex;
`;

export const DoctorListContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1.5rem;
  align-self: flex-start;
  background-color: none;
  text-decoration: none;
  flex-direction: column;
  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
  transition: background-color 0.1s ease-in;
`;

export const DoctorMainTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 0.7rem;
`;

export const DoctorSecondTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const DoctorName = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.8rem;
`;

export const DoctorCRM = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
`;

export const DoctorSpecialty = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.8rem;
`;

export const DoctorEmail = styled.p`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
`;

export const DoctorImage = styled.div<DoctorProps>`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-image: url(${props => props.DImage});
  background-image: no-repeat;
  background-size: cover;
`;

export const DoctorImageContainer = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
`;

export const ConfirmRegistrationContainer = styled.div`
  padding: 2.5rem;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const CancelButton = styled.button`
  width: 19rem;
  height: 5rem;
  color: ${props => props.theme.color.red};
  background-color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => props.theme.color.red};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;

export const CancelButtonContainer = styled.div`
  margin-top: 2.6rem;
  margin-left: 8.1rem;
  margin-right: 2.7rem;
  margin-bottom: 3.2rem;
`;

export const ConfirmButtonContainerModal = styled.div`
  margin-top: 2.6rem;
  margin-left: 2.7rem;
  margin-right: 8.1rem;
  margin-bottom: 3.2rem;
`;

export const ModalTitle = styled.div`
  display: flex;
  font: ${props => props.theme.font.bigTitle};
  color: ${props => props.theme.color.blue};
  justify-content: center;
`;

export const DoctorFirstTextContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 1.6rem;
`;

export const DoctorSecondTextContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const DoctorImageContainerModal = styled.div`
  display: flex;
`;

export const DoctorImageModal = styled.div<DoctorProps>`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-image: url(${props => props.DImage});
  background-image: no-repeat;
  background-size: cover;
`;

export const DoctorNameModal = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  margin-bottom: 1.6rem;
`;

export const DoctorCRMModal = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
`;
export const DoctorSpecialtyModal = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  margin-bottom: 1.6rem;
`;

export const DoctorEmailModal = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  margin-bottom: 3.2rem;
`;

export const RadioButton = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.6rem;
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
  input[type='radio']:after {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    position: relative;
    background-color: transparent;
    content: '';
    display: flex;
    visibility: visible;
    border: 0.3rem solid ${props => props.theme.color.blue};
    border-color: ${props => props.theme.color.blue};
  }

  input[type='radio']:checked:after {
    width: 0.6rem;
    height: 0.6rem;
    top: 0.4rem;
    left: 0.4rem;
    border-radius: 0.6rem;
    position: relative;
    background-color: ${props => props.theme.color.blue};
    content: '';
    display: flex;
    visibility: visible;
  }
`;

export const RadioButtonLabel = styled.label`
  margin-left: 1rem;
`;

export const LineRadioButton = styled.div`
  padding-left: 3rem;
  margin-bottom: 1.6rem;
  display: flex;
`;

export const RadioButtonInput = styled.input`
  height: 2rem;
  width: 2rem;
`;
