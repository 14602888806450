import api from '../../services/api';

interface Item {
  index_order: number;
}

const orderItems = (x: Item, y: Item): number => {
  if (x.index_order > y.index_order) return 1;
  if (x.index_order < y.index_order) return -1;

  return 0;
};

const updateItemIndex = async (
  isService: boolean,
  itemId: string,
  value: number,
  fixed?: boolean,
) => {
  await api.put(`/${isService ? 'services' : 'insurances'}/${itemId}`, {
    index_order: value,
    fixed_top: fixed ? fixed : false,
  });
};

export { orderItems, updateItemIndex };
