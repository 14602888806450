import styled from 'styled-components';

export const Container = styled.div`
  height: 4.7rem;
  background-color: ${props => props.theme.color.secondaryGray};
  padding: 0.8rem;
  display: flex;
  border-radius: 0.5rem;
`;

export const OptionTitleAndImageContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.8rem;
  margin-right: 1.74rem;
`;

export const OptionImage = styled.img`
  width: 5rem;
  height: 3.1rem;
  object-fit: contain;
`;

export const OptionTitle = styled.h3`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
  line-height: 1.5rem;
  align-self: center;
`;
