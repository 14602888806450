import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root {
    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html,
  body,
  #root {
    height: 100vh;
    position: relative;
    z-index: -10000;
  }

  body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.theme.color.backgroundGray};
    user-select: none;
    overflow-x: hidden !important;
  }

  button {
    cursor: pointer;
  }
`;
