import React, { useEffect } from 'react';
import {
  Container,
  Card,
  Triangle,
  OptionsContainer,
  TitleAndImageContainer,
  OptionImageContainer,
  OptionImage,
  OptionTitle,
  Options,
  EmptyList,
} from './AdditionCard.elements';
import { InputComponent } from '../index';

import { VectorAdd } from '../../assets/icons/index';

type AdditionCardProps = {
  options: any;
  selected?: any;
  selectedId?: any;
  changeOptions?: (newValue: any) => void;
  updateSelected?: (newValue: any) => void;
  updateSelectedId?: (newValue: any) => void;
  changeInput: any;
  placeholder?: string;
  onClickAction?: any;
};

const AdditionCard: React.FC<AdditionCardProps> = ({
  options,
  selected,
  selectedId,
  changeOptions,
  updateSelected,
  updateSelectedId,
  changeInput,
  placeholder,
  onClickAction,
}) => {
  //const [theOptions, setTheOptions] = useState<any>([]);
  //const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleAdd = (option: any) => {
    if (updateSelected && selected) {
      updateSelected([...selected, option]);
    }
    if (updateSelectedId && selectedId) {
      updateSelectedId([...selectedId, option.id]);
    }
    const newOptions: {
      id: string;
      name: string;
      photo_address: string;
    }[] = [];
    options.forEach(
      (opt: { id: string; name: string; photo_address: string }) => {
        if (opt.id != option.id) {
          newOptions.push(opt);
        }
      },
    );
    if (newOptions && changeOptions) {
      changeOptions(newOptions);
    }
    changeInput('');
  };

  useEffect(() => {
    return () => {
      changeInput('');
    };
  }, [changeInput]);

  return (
    <Container>
      <Triangle />
      <Card>
        <InputComponent
          fillInput={changeInput}
          height={'3.5rem'}
          font={'1.5rem'}
          placeHolder={placeholder}
          icon
        ></InputComponent>
        {options.length > 0 ? (
          <Options paddingRight={options.length >= 5 ? '1rem' : ''}>
            {options.map(
              (
                option: {
                  id: string;
                  name: string;
                  photo_address?: string;
                },
                index: number,
              ) => (
                <OptionsContainer key={index}>
                  <TitleAndImageContainer>
                    {option?.photo_address && (
                      <OptionImageContainer>
                        <OptionImage src={option.photo_address} />
                      </OptionImageContainer>
                    )}
                    <OptionTitle>{option.name}</OptionTitle>
                  </TitleAndImageContainer>
                  <VectorAdd
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleAdd(option);
                      if (onClickAction) {
                        onClickAction(option);
                      }
                    }}
                  />
                </OptionsContainer>
              ),
            )}
          </Options>
        ) : (
          <EmptyList>Nenhum resultado encontrado</EmptyList>
        )}
      </Card>
    </Container>
  );
};

export default AdditionCard;
