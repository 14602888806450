import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../services/api';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/global/index';

import {
  Container,
  Title,
  InputsContainer,
  Subtitle,
  AddContainer,
  AddTitle,
  ButtonsContainer,
  DenyModalButton,
  CancelModalButton,
  AdditionOptionsContainer,
  ModalContainer,
  ErrorContainer,
  ErrorMessage,
  LoaderContainer,
} from './AddServicesCard.elements';
import { InputComponent, Divisor, AdditionOptions } from '../index';
import { VectorAddBlue, Alert } from '../../assets/icons/index';
import { AdditionCard } from '../index';
import { phoneMask } from '../../utils/phoneMask';

type AddServicesProps = {
  type: string;
  serviceId?: string;
  leftButtonFunction?: () => void;
  rightButtonFunction?: () => void;
};

const AddServicesCard: React.FC<AddServicesProps> = ({
  type,
  serviceId,
  leftButtonFunction,
  rightButtonFunction,
}) => {
  const [insurances, setInsurances] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [insurancesServices, setInsurancesServices] = useState([]);
  const [oldInsurancesServices, setOldInsurancesServices] = useState([]);
  const [insurancesId, setInsurancesId] = useState([]);
  const [serviceIdentifier, setserviceIdentifier] = useState('');
  const [oldService, setOldService] = useState({
    id: '',
    name: '',
    phone: '',
    street_address: '',
    region: '',
    cep: '',
  });
  const [serviceName, setServiceName] = useState('');
  const [servicePhone, setServicePhone] = useState('');
  const [serviceStreet_address, setServiceStreet_address] = useState('');
  const [serviceRegion, setServiceRegion] = useState('');
  const [serviceCep, setServiceCep] = useState('');
  const [changes, setChanges] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Preencha todos os campos.');
  const [loadingService, setLoadingService] = useState(false);
  const [loadingInsurancesServices, setLoadingInsurancesServices] = useState(
    false,
  );
  const [searchInsurances, setSearchInsurances] = useState('');

  const menuRef = useRef<HTMLDivElement | null>(null);

  const loadService = useCallback(async () => {
    const res = await api.get(`/services/${serviceId}`);
    if (res && res.data) {
      setOldService({
        id: res.data.id,
        name: res.data.name,
        phone: res.data.phone,
        street_address: res.data.street_address,
        region: res.data.region,
        cep: res.data.cep,
      });
      setserviceIdentifier(res.data.id);
      setServiceName(res.data.name);
      setServicePhone(res.data.phone);
      setServiceStreet_address(res.data.street_address);
      setServiceRegion(res.data.region);
      setServiceCep('CEP: ' + res.data.cep);
    }
    setLoadingService(true);
  }, [serviceId]);

  const loadInsurancesServices = useCallback(async () => {
    const res = await api.get(`/insurances-services?serviceId=${serviceId}`);
    if (res && res.data) {
      const data = res.data.map(
        (insuranceService: {
          id: string;
          insurance: { id: string; name: string; photo_address: string };
        }) => ({
          relationId: insuranceService.id,
          id: insuranceService.insurance.id,
          name: insuranceService.insurance.name,
          photo_address: insuranceService.insurance.photo_address,
        }),
      );
      setInsurancesServices(data);
      setOldInsurancesServices(data);
      const secondData = res.data.map(
        (insuranceService: { insurance: { id: string } }) =>
          insuranceService.insurance.id,
      );
      setInsurancesId(secondData);
    }
    setLoadingInsurancesServices(true);
  }, [serviceId]);

  const deleteInsurancesServices = async (relationId: string) => {
    const res = await api.delete(`/insurances-services/${relationId}`);
    if (res) {
      loadInsurancesServices();
    }
  };

  const loadInsurances = async () => {
    const res = await api.get(`insurances?text=${searchInsurances}`);
    if (res && res.data) {
      const data = res.data.map(
        (insurance: { id: string; name: string; photo_address: string }) => ({
          id: insurance.id,
          name: insurance.name,
          photo_address: insurance.photo_address,
        }),
      );
      filterInsurances(data, insurancesId);
    }
  };

  const createInsuranceService = async (
    insuranceId: string,
    serviceId: string,
  ) => {
    await api.post('insurances-services', {
      insurance_id: insuranceId,
      service_id: serviceId,
    });
  };

  const handleDeleteInsurancesServices = async () => {
    oldInsurancesServices.forEach(
      (oldInsuranceService: {
        id: string;
        name: string;
        photo_address: string;
        relationId: string;
      }) => {
        let flag = false;
        insurancesServices.forEach(
          (newInsuranceService: {
            id: string;
            name: string;
            photo_address: string;
          }) => {
            if (oldInsuranceService.id == newInsuranceService.id) {
              flag = true;
            }
          },
        );
        if (flag == false) {
          deleteInsurancesServices(oldInsuranceService.relationId);
        }
      },
    );
  };

  const handleCreateInsurancesServices = async () => {
    insurancesServices.forEach(
      (newInsuranceService: {
        id: string;
        name: string;
        photo_address: string;
      }) => {
        let flag = false;
        oldInsurancesServices.forEach(
          (oldInsuranceService: {
            id: string;
            name: string;
            photo_address: string;
            relationId: string;
          }) => {
            if (newInsuranceService.id == oldInsuranceService.id) {
              flag = true;
            }
          },
        );
        if (flag == false) {
          createInsuranceService(newInsuranceService.id, serviceIdentifier);
        }
      },
    );
  };

  const updateService = async () => {
    if (
      oldService.name != serviceName ||
      oldService.phone != servicePhone ||
      oldService.street_address != serviceStreet_address ||
      oldService.region != serviceRegion ||
      oldService.cep != serviceCep
    ) {
      const res = await api.put(`/services/${serviceId}`, {
        name: serviceName,
        phone: servicePhone,
        street_address: serviceStreet_address,
        region: serviceRegion,
        cep:
          serviceCep
            .replace(/[^\d]+/g, '')
            .substr(0, serviceCep.replace(/[^\d]+/g, '').length - 3) +
          '-' +
          serviceCep.replace(/[^\d]+/g, '').substr(-3),
      });
      if (res && res.status == 200) {
        setOldService({
          id: serviceIdentifier,
          name: serviceName,
          phone: servicePhone,
          street_address: serviceStreet_address,
          region: serviceRegion,
          cep: serviceCep,
        });
      }
    }
    await handleDeleteInsurancesServices();
    await handleCreateInsurancesServices();
  };

  const addService = async () => {
    const res = await api.post('services', {
      name: serviceName,
      phone: servicePhone,
      street_address: serviceStreet_address,
      region: serviceRegion,
      cep:
        serviceCep
          .replace(/[^\d]+/g, '')
          .substr(0, serviceCep.replace(/[^\d]+/g, '').length - 3) +
        '-' +
        serviceCep.replace(/[^\d]+/g, '').substr(-3),
    });
    if (res && res.data) {
      insurancesServices.forEach(async (insuranceService: { id: string }) => {
        await api.post('insurances-services', {
          insurance_id: insuranceService.id,
          service_id: res.data.id,
        });
      });
    }
  };

  const filterInsurances = (allInsurances: any, chooseInsurancesIds: any) => {
    const filteredInsurances: { id: string }[] = [];

    if (allInsurances && chooseInsurancesIds) {
      allInsurances.forEach((insurance: { id: string }) => {
        let flag = false;
        chooseInsurancesIds.forEach((id: string) => {
          if (insurance.id == id) {
            flag = true;
          }
        });
        if (flag == false) {
          filteredInsurances.push(insurance);
        }
      });
      setInsurances(filteredInsurances);
    }
  };

  const removeChoosedOptions = (insuranceService: { id: string }) => {
    const newInsurancesServices: any = [];
    const newInsurancesServicesId: any = [];
    insurancesServices.forEach((opt: { id: string }) => {
      if (opt.id != insuranceService.id) {
        newInsurancesServices.push(opt);
      }
    });
    insurancesId.forEach((id: string) => {
      if (id != insuranceService.id) {
        newInsurancesServicesId.push(id);
      }
    });
    setInsurancesServices(newInsurancesServices);
    setInsurancesId(newInsurancesServicesId);
    setInsurances([insuranceService, ...insurances]);
  };

  const handleAddConfirmation = () => {
    if (
      '' != serviceName &&
      '' != servicePhone &&
      '' != serviceStreet_address &&
      '' != serviceRegion &&
      '' != serviceCep &&
      0 != insurancesServices.length
    ) {
      addService();
      if (rightButtonFunction) {
        rightButtonFunction();
      }
    } else {
      if (insurancesServices.length == 0) {
        setErrorMessage('Selecione ao menos um convênio');
      } else {
        setErrorMessage('Preencha todos os campos');
      }
      setChanges(true);
    }
  };

  const handleEditConfirmation = () => {
    if (
      oldService.name != serviceName ||
      oldService.phone != servicePhone ||
      oldService.street_address != serviceStreet_address ||
      oldService.region != serviceRegion ||
      oldService.cep != serviceCep ||
      0 != insurancesServices.length
    ) {
      updateService();
      if (rightButtonFunction) {
        rightButtonFunction();
      }
    } else {
      if (insurancesServices.length == 0) {
        setErrorMessage('Selecione ao menos um convênio');
      } else {
        setErrorMessage('Nenhuma alteração feita para ser salva.');
      }
      setChanges(true);
    }
  };

  const handleOutsideClick = (e: { target: any }) => {
    const reference = menuRef.current;
    if (reference && !reference.contains(e.target)) {
      setShowModal(!showModal);
    }
  };

  useEffect(() => {
    if (type == 'edit') {
      loadService();
      loadInsurancesServices();
    }
  }, []);

  useEffect(() => {
    loadInsurances();
  }, [insurancesId]);

  useEffect(() => {
    loadInsurances();
  }, [searchInsurances]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <>
      {type == 'edit' && (
        <Container>
          <Title>Editar serviço</Title>
          {loadingService && loadingInsurancesServices ? (
            <>
              <InputsContainer>
                <InputComponent
                  valueToShow={serviceName}
                  placeHolder="Nome"
                  fillInput={setServiceName}
                  height="5.5rem"
                />
                <InputComponent
                  valueToShow={phoneMask(servicePhone)}
                  placeHolder="(00) 0000-0000"
                  fillInput={setServicePhone}
                  height="5.5rem"
                />
              </InputsContainer>
              <Divisor />
              <InputsContainer>
                <InputComponent
                  valueToShow={serviceStreet_address}
                  placeHolder="Endereço"
                  fillInput={setServiceStreet_address}
                  height="5.5rem"
                />
                <InputComponent
                  valueToShow={serviceRegion}
                  placeHolder="Região/estado"
                  fillInput={setServiceRegion}
                  height="5.5rem"
                />
                <InputComponent
                  valueToShow={serviceCep}
                  placeHolder="CEP: 00000-000"
                  fillInput={setServiceCep}
                  height="5.5rem"
                />
              </InputsContainer>
              <Divisor />
              <Subtitle>Convênios aceitos nesse local:</Subtitle>
              <AdditionOptionsContainer>
                {insurancesServices.map(
                  (insuranceService: {
                    relationId: string;
                    id: string;
                    name: string;
                    photo_address: string;
                  }) => (
                    <AdditionOptions
                      title={insuranceService.name}
                      image={insuranceService.photo_address}
                      deleteFunction={() =>
                        removeChoosedOptions(insuranceService)
                      }
                    />
                  ),
                )}
              </AdditionOptionsContainer>
              <AddContainer>
                <VectorAddBlue
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowModal(!showModal)}
                />
                <AddTitle onClick={() => setShowModal(!showModal)}>
                  Adicionar convênio
                </AddTitle>
                {showModal && (
                  <ModalContainer ref={menuRef}>
                    <AdditionCard
                      options={insurances}
                      changeOptions={setInsurances}
                      selected={insurancesServices}
                      selectedId={insurancesId}
                      updateSelected={setInsurancesServices}
                      updateSelectedId={setInsurancesId}
                      changeInput={setSearchInsurances}
                      placeholder="Buscar convênio"
                    ></AdditionCard>
                  </ModalContainer>
                )}
              </AddContainer>
              {changes && (
                <ErrorContainer>
                  <Alert />
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </ErrorContainer>
              )}
              <ButtonsContainer modalOpen={showModal}>
                <CancelModalButton onClick={leftButtonFunction}>
                  Cancelar
                </CancelModalButton>
                <DenyModalButton onClick={handleEditConfirmation}>
                  Salvar serviços/alterações
                </DenyModalButton>
              </ButtonsContainer>
            </>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Container>
      )}
      {type == 'add' && (
        <Container>
          <Title>Adicionar novo serviço</Title>
          <InputsContainer>
            <InputComponent
              valueToShow={serviceName}
              placeHolder="Nome"
              fillInput={setServiceName}
              height="5.5rem"
            />
            <InputComponent
              valueToShow={phoneMask(servicePhone)}
              placeHolder="(00) 0000-0000"
              fillInput={setServicePhone}
              height="5.5rem"
            />
          </InputsContainer>
          <Divisor />
          <InputsContainer>
            <InputComponent
              valueToShow={serviceStreet_address}
              placeHolder="Endereço"
              fillInput={setServiceStreet_address}
              height="5.5rem"
            />
            <InputComponent
              valueToShow={serviceRegion}
              placeHolder="Região/estado"
              fillInput={setServiceRegion}
              height="5.5rem"
            />
            <InputComponent
              valueToShow={serviceCep}
              placeHolder="CEP: 00000-000"
              fillInput={setServiceCep}
              height="5.5rem"
            />
          </InputsContainer>
          <Divisor />
          <Subtitle>Convênios aceitos nesse local:</Subtitle>
          <AdditionOptionsContainer>
            {insurancesServices.map(
              (insuranceService: {
                id: string;
                name: string;
                photo_address: string;
              }) => (
                <AdditionOptions
                  title={insuranceService.name}
                  image={insuranceService.photo_address}
                  deleteFunction={() => removeChoosedOptions(insuranceService)}
                />
              ),
            )}
          </AdditionOptionsContainer>
          <AddContainer>
            <VectorAddBlue
              style={{ cursor: 'pointer' }}
              onClick={() => setShowModal(!showModal)}
            />
            <AddTitle onClick={() => setShowModal(!showModal)}>
              Adicionar convênio
            </AddTitle>
            {showModal && (
              <ModalContainer ref={menuRef}>
                <AdditionCard
                  options={insurances}
                  changeOptions={setInsurances}
                  selected={insurancesServices}
                  selectedId={insurancesId}
                  updateSelected={setInsurancesServices}
                  updateSelectedId={setInsurancesId}
                  changeInput={setSearchInsurances}
                  placeholder="Buscar convênio"
                ></AdditionCard>
              </ModalContainer>
            )}
          </AddContainer>
          {changes && (
            <ErrorContainer>
              <Alert />
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorContainer>
          )}
          <ButtonsContainer modalOpen={showModal}>
            <CancelModalButton onClick={leftButtonFunction}>
              Cancelar
            </CancelModalButton>
            <DenyModalButton onClick={handleAddConfirmation}>
              Salvar serviços/alterações
            </DenyModalButton>
          </ButtonsContainer>
        </Container>
      )}
    </>
  );
};
export default AddServicesCard;
