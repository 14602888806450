import React, { useState } from 'react';
import { Container, DropDownMenu, ActionContainer } from './CaseMenu.elements';
import { Link } from 'react-router-dom';

import { ThreeDots, Delete, Assignment, Edit } from '../../../assets/icons';
import MenuOptions from '../MenuOptions';
interface CaseMenuProps {
  openModal: () => void;
  openDeleteModal: () => void;
}

const CaseMenu: React.FC<CaseMenuProps> = ({ openModal, openDeleteModal }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Container onClick={() => setOpen(!open)}>
        <ThreeDots />
        {open && (
          <DropDownMenu>
            <ActionContainer onClick={openModal}>
              <MenuOptions
                title="Atualizar status"
                icon={Assignment}
                redirectTo={'/'}
              />
            </ActionContainer>

            <ActionContainer onClick={openModal}>
              <MenuOptions
                title="Editar info do paciente"
                icon={Edit}
                redirectTo={'/'}
              />
            </ActionContainer>
            <ActionContainer onClick={openDeleteModal}>
              <MenuOptions title="Apagar" icon={Delete} redirectTo={'/'} />
            </ActionContainer>
          </DropDownMenu>
        )}
      </Container>
    </>
  );
};

export default CaseMenu;
