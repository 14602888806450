import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';

interface DoctorProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  photo: string;
  zIndex: number;
}

export const PhotosContainer = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row-reverse;
`;

export const Doctor = styled.div<DoctorProps>`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin-top: 0.6rem;
  margin-left: -1rem;

  background-image: url(${props => props.photo});
  background-size: 5rem;
  background-image: no-repeat;
  background-size: cover;
  z-index: ${props => props.zIndex};
`;
