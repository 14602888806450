/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import api from '../../services/api';

import {
  DenyButtonContainer,
  DoctorFirstPart,
  DoctorListContainer,
  DoctorMainTextContainer,
  DoctorSecondTextContainer,
  DoctorFirstTextContainerModal,
  DoctorSecondTextContainerModal,
  DoctorName,
  DoctorCRM,
  DoctorSpecialty,
  DoctorEmail,
  DoctorNameModal,
  DoctorCRMModal,
  DoctorSpecialtyModal,
  DoctorEmailModal,
  DoctorImageContainer,
  DoctorImageContainerModal,
  DoctorImage,
  DoctorImageModal,
  ConfirmRegistrationContainer,
  ButtonContainer,
  CancelButtonContainer,
  ConfirmButtonContainer,
  ConfirmButtonContainerModal,
  ModalTitle,
  DenyButton,
  ConfirmButton,
  RadioButton,
  LineRadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from './WaitingConfirmation.elements';

import Modal from '../Modal';
import { DenyCard } from '../index';
import DoctorDivisor from '../DoctorsList/DoctorDivisor';

type infos = {
  userId: string;
  key: string;
  doctorImage: string;
  doctorName: string;
  CRM: number;
  doctorSpecialty: string;
  doctorEmail: string;
  sonheUser: boolean;
  confirmUser: boolean;
  deleteUser: () => void;
  loadUser: () => void;
};

const Doctor: React.FC<infos> = ({
  userId,
  doctorImage,
  doctorName,
  doctorSpecialty,
  doctorEmail,
  CRM,
  sonheUser,
  deleteUser,
  loadUser,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [radio, setRadio] = useState(sonheUser);
  const isConfirmedUser = async () => {
    await api.put(`/users/${userId}`, {
      isConfirmed: true,
    });
    loadUser();
  };

  const isSonheUser = async () => {
    await api.put(`/users/${userId}`, {
      isSonhe: radio,
    });
    loadUser();
  };
  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const openOrCloseDenyModal = () => {
    setShowDenyModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  return (
    <>
      <DoctorListContainer>
        <DoctorFirstPart>
          <DoctorImageContainer>
            <DoctorImage DImage={doctorImage}></DoctorImage>
          </DoctorImageContainer>
          <DoctorMainTextContainer>
            <DoctorName>{doctorName}</DoctorName>
            <DoctorCRM>CRM: {CRM}</DoctorCRM>
            <br />
          </DoctorMainTextContainer>
          <DenyButtonContainer>
            <DenyButton onClick={openOrCloseDenyModal}>Cancelar</DenyButton>
          </DenyButtonContainer>
          <ConfirmButtonContainer>
            <ConfirmButton onClick={openOrCloseModal}>Confirmar</ConfirmButton>
          </ConfirmButtonContainer>
        </DoctorFirstPart>
        <DoctorSecondTextContainer>
          <DoctorSpecialty>{doctorSpecialty}</DoctorSpecialty>
          <DoctorEmail>{doctorEmail}</DoctorEmail>
        </DoctorSecondTextContainer>
      </DoctorListContainer>
      <DoctorDivisor />
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ConfirmRegistrationContainer>
          <ModalTitle>Confirmar Cadastro</ModalTitle>
          <DoctorImageContainerModal>
            <DoctorImageModal DImage={doctorImage}></DoctorImageModal>
          </DoctorImageContainerModal>
          <DoctorFirstPart>
            <DoctorFirstTextContainerModal>
              <DoctorNameModal>{doctorName}</DoctorNameModal>
              <DoctorCRMModal>CRM: {CRM}</DoctorCRMModal>
              <br />
            </DoctorFirstTextContainerModal>
            <RadioButton>
              <LineRadioButton>
                <RadioButtonInput
                  type="radio"
                  checked={!radio}
                  value="Encaminhador"
                  onChange={() => setRadio(!radio)}
                />
                <RadioButtonLabel>Encaminhador</RadioButtonLabel>
              </LineRadioButton>
              <LineRadioButton>
                <RadioButtonInput
                  type="radio"
                  checked={radio}
                  value="MedicoSOnHe"
                  onChange={() => setRadio(!radio)}
                />
                <RadioButtonLabel>Médico SOnHe</RadioButtonLabel>
              </LineRadioButton>
            </RadioButton>
          </DoctorFirstPart>
          <DoctorSecondTextContainerModal>
            <DoctorSpecialtyModal>{doctorSpecialty}</DoctorSpecialtyModal>
            <DoctorEmailModal>{doctorEmail}</DoctorEmailModal>
          </DoctorSecondTextContainerModal>
          <DoctorDivisor />
          <ButtonContainer>
            <CancelButtonContainer>
              <DenyButton onClick={openOrCloseModal}>Cancelar</DenyButton>
            </CancelButtonContainer>
            <ConfirmButtonContainerModal>
              <ConfirmButton
                onClick={() => {
                  openOrCloseModal();
                  isSonheUser();
                  isConfirmedUser();
                }}
              >
                Confirmar
              </ConfirmButton>
            </ConfirmButtonContainerModal>
          </ButtonContainer>
        </ConfirmRegistrationContainer>
      </Modal>
      <Modal showModal={showDenyModal} setShowModal={setShowDenyModal}>
        <DenyCard
          title="Você tem certeza que deseja negar essa solicitação?"
          description="Esta é uma ação irreversível. Tem certeza que deseja continuar?"
          rightButtonTitle="Negar"
          leftButtonFunction={openOrCloseDenyModal}
          rightButtonFunction={() => {
            openOrCloseDenyModal();
            deleteUser();
          }}
        />
      </Modal>
    </>
  );
};
export default Doctor;
