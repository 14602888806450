import styled from 'styled-components';

export const Container = styled.div`
  width: 72.6rem;
  height: 72.6rem;
  padding: 3.5rem 2rem;
  background-color: ${props => props.theme.color.modalGrey};
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 0.5px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const Title = styled.p`
  font: ${props => props.theme.font.smashTitle};
  align-self: flex-start;
`;

export const AdditionOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  margin-top: 1.6rem;
  gap: 1.6rem;
  width: 100%;
`;

export const AdditionCardContainer = styled.div`
  position: absolute;
  bottom: -20px;
  left: 200px;
`;

export const LittleModalWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 2.4rem;
  position: relative;
`;

export const AddPhotoContainer = styled.div`
  min-width: 11rem;
  max-width: 11rem;
  min-height: 11rem;
  max-height: 11rem;

  border-radius: 50%;
  background-color: ${props => props.theme.color.white};
  border: 0.5px solid #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AddPhotoIconContainer = styled.div``;

export const ProfileImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
`;

export const ProfileImage = styled.img`
  width: 12.8rem;
  height: 12.8rem;
  object-fit: contain;
  border-radius: 50%;
`;

export const ProfileImageEditButton = styled.button`
  display: flex;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  svg {
    height: 2.4rem;
    width: 2.4rem;

    cursor: pointer;

    path {
      fill: ${props => props.theme.color.white};
      fill-opacity: 1;
    }
  }
  padding: 1rem;
  position: absolute;
  border: none;
  border-radius: 50%;
  background-color: ${props => props.theme.color.blue};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  gap: 1.6rem;
`;

export const Subtitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  align-self: flex-start;
  margin-top: 3.2rem;
`;

export const AddContainer = styled.div`
  align-self: flex-start;
  display: flex;
  gap: 0.8rem;
  cursor: pointer;
`;

export const AddTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-self: center;
  height: 100%;
  align-items: flex-end;
`;

export const DenyModalButton = styled.button`
  height: 5rem;
  width: 29rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;

export const CancelModalButton = styled.button`
  height: 5rem;
  width: 29rem;
  padding: 1rem 1.5rem;
  boheight: 5rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  border: 1px solid ${props => props.theme.color.mainBrown};
  font: ${props => props.theme.font.featuredNormalText};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.mainBrown};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
  }
`;
