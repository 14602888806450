import React, { createContext, useState, useCallback, useContext } from 'react';

export interface IFilterProps {
  alphabeticOrder: string;
  searchText: string;
}

interface FilterContextProps {
  filters: IFilterProps;
  changeOrder(deadlineOrder: React.ChangeEvent<HTMLSelectElement>): void;
  changeSearchText(searchText: React.ChangeEvent<HTMLInputElement>): void;
}

const FilterContext = createContext<FilterContextProps>(
  {} as FilterContextProps,
);

export const FilterProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<IFilterProps>(() => {
    return { searchText: '', alphabeticOrder: '' } as IFilterProps;
  });

  const changeSearchText = useCallback(
    (searchText: React.ChangeEvent<HTMLInputElement>) => {
      setFilters(oldFilters => ({
        ...oldFilters,
        searchText: searchText.target.value,
      }));
    },
    [],
  );

  const changeOrder = useCallback(
    (alphabeticOrder: React.ChangeEvent<HTMLSelectElement>) => {
      setFilters(oldFilters => ({
        ...oldFilters,
        alphabeticOrder: alphabeticOrder.target.value,
      }));
    },
    [],
  );

  return (
    <FilterContext.Provider
      value={{
        filters,
        changeSearchText,
        changeOrder,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = (): FilterContextProps => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};
