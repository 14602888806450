import React, { useCallback, useState, useEffect } from 'react';
import api from '../../services/api';
import { Search } from '../../assets/icons';
import {
  ButtonContainer,
  CardsContainer,
  FiltersContainer,
  IconContainer,
  OrderSpecialty,
  SearchBar,
  SearchContainer,
} from './Specialties.elements';

import {
  BrownButton,
  Card,
  Divisor,
  InputCard,
  Modal,
  SpecialtyItem,
} from '../../components';

interface SpecialtyProps {
  id: string;
  name: string;
}

const Specialties: React.FC = () => {
  const [addModal, setAddModal] = useState(false);
  const [newSpecialty, setNewSpecialty] = useState('');
  const [searchText, setSearchText] = useState('');
  const [orderSpecialties, setOrderSpecialties] = useState('');
  const [isSpecialtyRepeated, setIsSpecialtyRepeated] = useState(false);
  const [specialty, setSpecialty] = useState<SpecialtyProps[]>(() => {
    return [] as SpecialtyProps[];
  });

  const filterSpecialties = useCallback((data: any): void => {
    data.sort(function (a: any, b: any) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });

    setSpecialty([...data]);
  }, []);

  const loadSpecialty = async () => {
    const res = await api.get(`/specialties/?text=${searchText}`);

    if (res && res.data) {
      const data = res.data.map((mySpecialty: any) => ({
        id: mySpecialty.id,
        name: mySpecialty.name,
      }));
      if (orderSpecialties === 'Alphabetic') {
        filterSpecialties(data);
      } else {
        setSpecialty(data);
      }
    }
  };

  const addSpecialty = async () => {
    if (checkSpecialtyRepeatedName()) {
      await api.post('/specialties', {
        name: newSpecialty,
      });
      setAddModal(!addModal);
    }
  };

  const checkSpecialtyRepeatedName = () => {
    // eslint-disable-next-line no-var
    var repetitions = 0;
    specialty.map(s => {
      if (s.name.toUpperCase() == newSpecialty.toUpperCase()) {
        setIsSpecialtyRepeated(true);
        repetitions++;
      }
    });
    if (repetitions) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    loadSpecialty();
  }, [specialty, orderSpecialties]);

  return (
    <>
      <CardsContainer>
        <ButtonContainer>
          <BrownButton
            title={'Adicionar nova especialidade'}
            handleButton={() => {
              setIsSpecialtyRepeated(false);
              setAddModal(!addModal);
            }}
          />
        </ButtonContainer>
        <FiltersContainer>
          <SearchContainer>
            <IconContainer>
              <Search />
            </IconContainer>
            <SearchBar
              placeholder={'Buscar especialidades pelo nome'}
              onChange={(e: any) => setSearchText(e.target.value)}
            ></SearchBar>
          </SearchContainer>
          <OrderSpecialty
            onChange={(e: any) => setOrderSpecialties(e.target.value)}
          >
            <option value="" selected>
              Ordenar por
            </option>
            <option value="Alphabetic">Ordem alfabética</option>
          </OrderSpecialty>
        </FiltersContainer>
        <Card title="Especialidades SOnHe">
          <Divisor width={100} />
          {specialty.map(({ id, name }) => (
            <SpecialtyItem id={id} title={name} />
          ))}
        </Card>
      </CardsContainer>
      <Modal showModal={addModal} setShowModal={setAddModal}>
        <InputCard
          fillInput={setNewSpecialty}
          rightButtonTitle="Salvar especialidade"
          rightButtonFunction={() => {
            addSpecialty();
          }}
          leftButtonFunction={() => {
            setAddModal(!addModal);
          }}
          placeholder="Nome da especialidade"
          error={isSpecialtyRepeated}
          errorMessage="Já existe uma especialidade com esse nome"
        />
      </Modal>
    </>
  );
};

export default Specialties;
