import React from 'react';

import {
  Container,
  NotificationPhoto,
  ItemsContainer,
  NotificationText,
  Deadline,
  Triangle,
} from './NotificationItem.elements';

import { NotificationProps } from '../NotificationBox';

const NotificationItem: React.FC<NotificationProps> = ({
  title,
  description,
  photo,
  time,
  redirectTo,
}) => {
  return (
    <>
      <Triangle />
      <Container onClick={redirectTo}>
        <NotificationPhoto photo={photo} />
        <ItemsContainer>
          <NotificationText>
            {' '}
            <strong>{title}</strong> {description}
          </NotificationText>
          <Deadline> {time} </Deadline>
        </ItemsContainer>
      </Container>
    </>
  );
};

export default NotificationItem;
