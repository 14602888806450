import React, { useCallback, useState, useEffect } from 'react';
import api from '../../services/api';
import {
  FilterProvider,
  IFilterProps,
} from '../../components/PatientsControl/DoctorPatients/FilterContext';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/global/index';

import {
  CardsContainer,
  EmptyList,
  LoaderContainer,
} from './PatientsControl.elements';

import {
  DoctorPatients,
  Card,
  SearchPatients,
  Divisor,
} from '../../components';

interface PatientsProps {
  id: string;
  patient_id: string;
  name: string;
  forwarder: string;
  forwarder_surname: string;
}

const Patients: React.FC = () => {
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [patients, setPatients] = useState<PatientsProps[]>(() => {
    return [] as PatientsProps[];
  });

  const filterPatients = useCallback((data: any): void => {
    data.sort(function (a: any, b: any) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });

    setPatients([...data]);
  }, []);

  const loadPatients = useCallback(
    async (filters?: IFilterProps) => {
      const res = await api.get(
        `/patients-users/?text=${
          filters?.searchText !== undefined ? filters?.searchText : ''
        }`,
      );
      if (res && res.data) {
        const data = res.data.map((myPatient: any) => ({
          id: myPatient.patient.id,
          patient_id: myPatient.patient_id,
          name: myPatient.patient.name,
          forwarder: myPatient.forwarder.name,
          forwarder_surname: myPatient.forwarder.surname,
        }));

        if (filters?.alphabeticOrder === 'Alphabetic') {
          filterPatients(data);
        } else {
          setPatients(data);
        }
        setLoadingPatients(true);
      }
    },
    [filterPatients],
  );

  useEffect(() => {
    loadPatients();
  }, [loadPatients]);

  return (
    <FilterProvider>
      <CardsContainer>
        <SearchPatients placeHolder={'Buscar por nome do médico ou paciente'} />
        <Card title="Pacientes">
          <Divisor width={100} />
          {loadingPatients ? (
            <>
              <DoctorPatients patients={patients} loadPatients={loadPatients} />
              {!(patients.length > 0) && <EmptyList> Sem pacientes </EmptyList>}
            </>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
    </FilterProvider>
  );
};

export default Patients;
