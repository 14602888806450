import styled from 'styled-components';

export const CardsContainer = styled.div`
  max-width: 56%;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-areas:
    'card1'
    'card2';
  grid-gap: 4rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
`;

export const EmptyList = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black}
  position: relative;
  text-align: center;
  margin: 12rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
