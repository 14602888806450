import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoutes';

import {
  Login,
  Referrals,
  Doctors,
  Patients,
  SpecificPatient,
  Specialties,
  Services,
  UnderConstruction,
  Insurances,
} from '../pages';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <PrivateRoute path="/encaminhamentos" component={Referrals} isPrivate />
    <PrivateRoute
      path="/em-construção"
      component={UnderConstruction}
      isPrivate
    />
    <PrivateRoute path="/especialidades" component={Specialties} isPrivate />
    <PrivateRoute path="/controle-de-médicos" component={Doctors} isPrivate />
    <PrivateRoute
      path="/paciente/:patientUserId"
      component={SpecificPatient}
      isPrivate
    />
    <PrivateRoute
      path="/controle-de-pacientes"
      component={Patients}
      isPrivate
    />
    <PrivateRoute path="/serviços-sonhe" component={Services} isPrivate />
    <PrivateRoute path="/convênios-sonhe" component={Insurances} isPrivate />
    <Route>
      <Redirect to="/encaminhamentos" />
    </Route>
  </Switch>
);

export default Routes;
