/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import GlobalStyles from './assets/global/global';
import theme from './assets/global';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import AppProvider from './hooks';

const App = () => {
  // console.log('App.tsx');
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyles />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
