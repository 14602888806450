import React, { memo, useEffect } from 'react';
import { IFilterProps, useFilter } from './FilterContext';
import { Link } from 'react-router-dom';

import {
  DoctorPatientsContainer,
  TextContainer,
  PatientName,
  ForwardingContainer,
  PatientForwardedBy,
  ForwardedText,
} from './DoctorPatients.elements';

import { PatientDivisor } from '../../Referrals';

type infos = {
  id: string;
  patient_id: string;
  name: string;
  forwarder: string;
  forwarder_surname: string;
};

interface ListPatientsProps {
  patients: infos[];
  loadPatients(filters?: IFilterProps): void;
}

const DoctorPatients: React.FC<ListPatientsProps> = ({
  patients,
  loadPatients,
}: ListPatientsProps) => {
  const { filters } = useFilter();

  useEffect(() => {
    loadPatients(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      {patients?.map(
        ({ id, patient_id, name, forwarder, forwarder_surname }) => (
          <>
            <DoctorPatientsContainer as={Link} to={`/paciente/${id}`}>
              <TextContainer>
                <PatientName>{name}</PatientName>
                <ForwardingContainer>
                  <ForwardedText>Encaminhado por</ForwardedText>
                  <PatientForwardedBy>
                    {forwarder + ' ' + forwarder_surname}
                  </PatientForwardedBy>
                </ForwardingContainer>
              </TextContainer>
            </DoctorPatientsContainer>
            <PatientDivisor />
          </>
        ),
      )}
    </>
  );
};

export default memo(DoctorPatients);
