import styled from 'styled-components';

export const GlobalDiv = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(19, 19, 19, 0.5);
  z-index: 10000;
  top: 0;
`;

export const NavContainer = styled.nav`
  height: 5rem;
  width: 100vw;
  background-color: ${props => props.theme.color.mainBrown};
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 100000;
`;

export const MainContentContainer = styled.header`
  height: 100%;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1300px) {
    width: 85%;
  }
`;

export const ListOfItems = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListOfIcons = styled.div`
  height: 100%;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }

  svg {
    height: 3rem;
    cursor: pointer;

    path {
      fill: white;
      fill-opacity: 1;
    }
  }
`;

export const ExitContainer = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }

  transition: background-color 0.1s ease-in;
`;

export const ExitText = styled.p`
  font: ${props => props.theme.font.normalText};
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
`;
