import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  LeftContainer,
  DateContainer,
  RightContainer,
  Title,
  Description,
  Date,
  Line,
  BigLine,
  ShowMore,
} from './TimelineItem.elements';

type TimelineItemProps = {
  date: string;
  isSonhe: boolean;
  description: string;
  doctor: string;
  ind: number;
  numberOfItems: number;
  redirectTo: string;
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  date,
  isSonhe,
  description,
  doctor,
  ind,
  numberOfItems,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [text, setText] = useState('Ver mais');

  const seeMoreHandler = () => {
    setIsOpen(!isOpen);
    if (isOpen == true) {
      setText('Ver menos');
    } else {
      setText('Ver mais');
    }
  };

  return (
    <>
      <Container>
        <LeftContainer>
          <DateContainer>
            <Date>{date}</Date>
          </DateContainer>
          {ind === numberOfItems && <BigLine />}
          {ind !== numberOfItems && <Line />}
        </LeftContainer>
        <RightContainer>
          {isSonhe ? (
            <Title>
              <strong>Atendimento</strong> registrado por {doctor}.
            </Title>
          ) : (
            <Title>
              <strong>Encaminhamento</strong> feito por {doctor}.
            </Title>
          )}
          <Description open={isOpen}>{description}</Description>
          <ShowMore onClick={seeMoreHandler}>
            <strong>{text}</strong>
          </ShowMore>
        </RightContainer>
      </Container>
    </>
  );
};

export default TimelineItem;
