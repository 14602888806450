import styled from 'styled-components';
import { HTMLAttributes } from 'react';

interface InputProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLInputElement>> {
  height?: string;
  font?: string;
  icon?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 1rem;

  svg {
    height: 2.4rem;
    cursor: pointer;
    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;

export const Input = styled.input`
  background-color: ${props => props.theme.color.white};
  font-size: ${(props: InputProps) => (props.font ? props.font : '22px')};
  padding: ${(props: InputProps) =>
    props.icon ? '15px 15px 15px 40px' : '15px'};
  border: 0.5px solid #c9c9c9;
  border-radius: 5px;
  width: 100%;
  height: ${(props: InputProps) => (props.height ? props.height : '5.5rem')};
`;
