import styled from 'styled-components';

interface MenuCardProps {
  width?: string;
}

export const DropDownMenu = styled.div<MenuCardProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: ${props => (props.width ? props.width : '21rem')};
  background-color: ${props => props.theme.color.white};
  border: 1px solid #adadad;
  border-radius: 0.5rem;
  margin-top: 2.5rem;
  margin-left: -17rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
