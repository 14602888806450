import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';

export const ButtonContainer = styled.button`
  width: 27rem;
  height: 5rem;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.mainBrown};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => props.theme.color.mainBrown};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;
