import styled from 'styled-components';

export const CardsContainer = styled.div`
  max-width: 56%;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-areas:
    'card1'
    'card2';
  grid-gap: 3.2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const FiltersContainer = styled.div`
  max-width: 77%;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-gap: 2rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 1rem;

  svg {
    height: 2.4rem;
    cursor: pointer;
    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;

export const SearchBar = styled.input`
  width: 100%;
  padding: 1rem 1.5rem 1rem 4rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;

export const OrderSpecialty = styled.select`
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;
