import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Edit, Done, RedTrash } from '../../assets/icons';
import api from '../../services/api';
import { DenyCard, Modal } from '../../components';
import {
  Container,
  SaveButton,
  SaveText,
  SpecialtyInput,
  SpecialtyLabel,
  TextContainer,
  TrashContainer,
} from './SpecialtyItem.elements';

interface SpecialtyItemProps {
  id: string;
  title: string;
}

const SpecialtyItem: React.FC<SpecialtyItemProps> = ({ id, title }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [edition, setEdition] = useState(false);
  const [editTitle, setEditTitle] = useState(title);

  const deleteSpecialty = async () => {
    await api.delete(`/specialties/${id}`);
  };

  const changeTitle = async () => {
    try {
      if (title != editTitle) {
        await api.put(`/specialties/${id}`, {
          name: editTitle,
        });
      }
      setEdition(!edition);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container>
        {edition ? (
          <TextContainer>
            <SpecialtyInput
              placeholder={title}
              defaultValue={title}
              onChange={e => {
                if (e.target.value != '' && e.target.value != editTitle) {
                  setEditTitle(e.target.value);
                }
              }}
            />
            <SaveButton onClick={changeTitle}>
              <SaveText>Salvar</SaveText>
              <Done />
            </SaveButton>
          </TextContainer>
        ) : (
          <TextContainer>
            <SpecialtyLabel>{title}</SpecialtyLabel>
            <Edit onClick={() => setEdition(!edition)} />
          </TextContainer>
        )}
        <TrashContainer>
          <RedTrash onClick={() => setDeleteModal(!deleteModal)} />
        </TrashContainer>
      </Container>
      <Modal showModal={deleteModal} setShowModal={setDeleteModal}>
        <DenyCard
          title={'Tem certeza que deseja deletar essa especialidade?'}
          description="Esta é uma ação irreversível. Tem certeza que deseja continuar?"
          rightButtonTitle="Apagar"
          leftButtonFunction={() => setDeleteModal(!deleteModal)}
          rightButtonFunction={() => {
            deleteSpecialty();
            setDeleteModal(!deleteModal);
          }}
        />
      </Modal>
    </>
  );
};
export default SpecialtyItem;
