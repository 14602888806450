import React, { useRef, useEffect } from 'react';

import DropdownOptions from './DropdownOptions';

import { Container, DropDownMenu, Triangle } from './NavbarOptions.elements';

type NavbarOptionsProps = {
  options?: any;
  closeModal: () => void;
};

const NavbarOptions: React.FC<NavbarOptionsProps> = ({
  options,
  closeModal,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (e: { target: any }) => {
    const reference = menuRef.current;
    if (reference && !reference.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <>
      <Triangle />
      <Container ref={menuRef}>
        <DropDownMenu>
          {options.map((option: { title: string; path: string }) => (
            <DropdownOptions
              closeModal={closeModal}
              title={option.title}
              path={option.path}
            ></DropdownOptions>
          ))}
        </DropDownMenu>
      </Container>
    </>
  );
};

export default NavbarOptions;
