import React, { useEffect, useState } from 'react';

import { Divisor, InputComponent } from '../index';

import { Alert } from '../../assets/icons/index';

import {
  Container,
  Title,
  Button,
  CancelButton,
  ButtonsContainer,
  ErrorContainer,
  ErrorMessage,
} from './InputCard.elements';

interface InputCardProps {
  fillInput: (value: string) => void;
  placeholder?: string;
  rightButtonTitle: string;
  errorMessage?: string;
  error?: boolean;
  leftButtonFunction?: () => void;
  rightButtonFunction?: () => void;
}

const InputCard: React.FC<InputCardProps> = ({
  fillInput,
  placeholder,
  error,
  errorMessage,
  rightButtonTitle,
  leftButtonFunction,
  rightButtonFunction,
}) => {
  return (
    <Container>
      <Title>Adicionar nova especialidade</Title>
      <InputComponent
        fillInput={fillInput}
        placeHolder={placeholder}
      ></InputComponent>
      {error ? (
        <ErrorContainer>
          <Alert />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorContainer>
      ) : null}
      <ButtonsContainer>
        <CancelButton onClick={leftButtonFunction}>Cancelar</CancelButton>
        <Button onClick={rightButtonFunction}>{rightButtonTitle}</Button>
      </ButtonsContainer>
    </Container>
  );
};

export default InputCard;
