import styled from 'styled-components';

export const SearchInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem 1rem 4rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`;
