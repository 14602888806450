import styled from 'styled-components';

interface NavTextProps {
  selected?: boolean;
  dropdown?: boolean;
}

export const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1.5rem 1rem 1.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }

  transition: background-color 0.1s ease-in;

  @media only screen and (max-width: 1346px) {
    padding: ${(props: NavTextProps) =>
      props.dropdown ? '0.4rem 1rem 1.5rem 1rem' : '1.5rem 1rem 1.5rem 1rem'};
  }

  @media only screen and (max-width: 1300px) {
    padding: ${(props: NavTextProps) =>
      props.dropdown ? '1.5rem 1rem 1.5rem 1rem' : '1.5rem 1rem 1.5rem 1rem'};
  }

  @media only screen and (max-width: 1108px) {
    padding: ${(props: NavTextProps) =>
      props.dropdown ? '0.4rem 1rem 1.5rem 1rem' : '1.5rem 1rem 1.5rem 1rem'};
  }
`;

export const NavText = styled.p`
  font: ${props => props.theme.font.navbarText};
  font-weight: ${(props: NavTextProps) => (props.selected ? 700 : 400)};
  color: white;
  text-transform: uppercase;
  text-decoration: none;
`;

export const BigContainer = styled.div`
  margin-top: 0.1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;
