import React from 'react';
import { DropDownMenu } from './MenuCard.elements';

import MenuOptions from '../MenuOptions';

type MenuProps = {
  options: any;
  width?: string;
};

const MenuCard: React.FC<MenuProps> = ({ options, width }) => {
  return (
    <>
      <DropDownMenu width={width}>
        {options.map(
          (option: {
            title: string;
            icon: any;
            block: boolean;
            color: string;
            action: any;
          }) => (
            <MenuOptions
              title={option.title}
              icon={option.icon}
              block={option.block}
              color={option.color}
              action={option.action}
            />
          ),
        )}
      </DropDownMenu>
    </>
  );
};

export default MenuCard;
