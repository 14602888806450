import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/global/index';

import {
  Doctor,
  Card,
  WaitingConfirmationCard,
  Divisor,
} from '../../components';
import {
  Container,
  CardsContainer,
  Wrapper,
  LoaderContainer,
} from './Doctors.elements';

interface UserProps {
  email: string;
  id: string;
  name: string;
  surname: string;
  crm: number;
  speciality: string;
  photo_address: string;
  isSonhe: boolean;
  isConfirmed: boolean;
  isAdmin: boolean;
}

const Doctors: React.FC = () => {
  const [loadingDoctors, setLoadingDoctors] = useState(false);
  const [user, setUser] = useState<UserProps[]>(() => {
    return [] as UserProps[];
  });

  const loadUser = async () => {
    const res = await api.get(`/users/?speciality=true`);

    if (res && res.data) {
      const data = res.data.map((myUser: any) => ({
        email: myUser.email,
        id: myUser.id,
        name: myUser.name,
        surname: myUser.surname,
        crm: myUser.crm,
        speciality: myUser.users_specialties[0]?.speciality?.name,
        photo_address: myUser.photo_address,
        isSonhe: myUser.isSonhe,
        isConfirmed: myUser.isConfirmed,
        isAdmin: myUser.isAdmin,
      }));

      setUser(data);
      setLoadingDoctors(true);
    }
  };

  const deleteUser = async (doctorId: string) => {
    await api.delete(`/users/${doctorId}`);
    loadUser();
  };
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Container>
      <CardsContainer>
        <Card title="Aguardando Confirmação">
          <Divisor width={100} />
          {loadingDoctors ? (
            <Wrapper>
              {user.map(
                ({
                  id,
                  name,
                  surname,
                  crm,
                  speciality,
                  photo_address,
                  email,
                  isSonhe,
                  isConfirmed,
                  isAdmin,
                }) =>
                  !isAdmin &&
                  !isConfirmed && (
                    <WaitingConfirmationCard
                      userId={id}
                      key={id}
                      doctorImage={photo_address}
                      doctorName={name + ' ' + surname}
                      CRM={crm}
                      doctorSpecialty={
                        speciality ? speciality : 'Especialidade não adicionada'
                      }
                      doctorEmail={email}
                      deleteUser={() => deleteUser(id)}
                      confirmUser={isConfirmed}
                      sonheUser={isSonhe}
                      loadUser={loadUser}
                    />
                  ),
              )}
            </Wrapper>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
      <CardsContainer>
        <Card title="Médicos SOnHe">
          <Divisor width={100} />
          {loadingDoctors ? (
            <Wrapper>
              {user.map(
                ({
                  id,
                  name,
                  surname,
                  crm,
                  speciality,
                  photo_address,
                  email,
                  isSonhe,
                  isConfirmed,
                  isAdmin,
                }) =>
                  !isAdmin &&
                  isConfirmed &&
                  isSonhe && (
                    <Doctor
                      userId={id}
                      key={id}
                      doctorImage={photo_address}
                      doctorName={name + ' ' + surname}
                      CRM={crm}
                      doctorSpecialty={
                        speciality ? speciality : 'Especialidade não adicionada'
                      }
                      doctorEmail={email}
                      sonheUser={isSonhe}
                      loadUser={loadUser}
                      deleteUser={() => deleteUser(id)}
                    />
                  ),
              )}
            </Wrapper>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
      <CardsContainer>
        <Card title="Encaminhadores">
          <Divisor width={100} />
          {loadingDoctors ? (
            <Wrapper>
              {user.map(
                ({
                  id,
                  name,
                  surname,
                  crm,
                  speciality,
                  photo_address,
                  email,
                  isSonhe,
                  isConfirmed,
                  isAdmin,
                }) =>
                  !isAdmin &&
                  isConfirmed &&
                  !isSonhe && (
                    <Doctor
                      userId={id}
                      key={id}
                      doctorImage={photo_address}
                      doctorName={name + ' ' + surname}
                      CRM={crm}
                      doctorSpecialty={
                        speciality ? speciality : 'Especialidade não adicionada'
                      }
                      doctorEmail={email}
                      sonheUser={isSonhe}
                      loadUser={loadUser}
                      deleteUser={() => deleteUser(id)}
                    />
                  ),
              )}
            </Wrapper>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
    </Container>
  );
};

export default Doctors;
