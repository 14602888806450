import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;
export const Wrapper = styled.div`
  max-height: 80vh;
  overflow: auto;
  overflow-x: hidden;
`;

export const DropDownMenu = styled.div`
  position: absolute;
  z-index: 9000;
  top: 5rem;
  left: -25rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 15px;

  user-select: none;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

export const NotificationTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};
  margin-bottom: 0.5rem;
`;
