import React from 'react';
import { ButtonContainer } from './BrownButton.elements';

interface ButtonProps {
  title?: string;
  handleButton?: () => void;
}

const BrownButton: React.FC<ButtonProps> = ({ title, handleButton }) => {
  return (
    <>
      <ButtonContainer onClick={handleButton}>{title}</ButtonContainer>
    </>
  );
};
export default BrownButton;
