// @ts-check
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { Divisor, Modal } from '../../components';
import CaseMenu from './CaseMenu';

import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/global/index';

import {
  HeaderContainer,
  Title,
  Container,
  SubtitleContainer,
  StatusName,
  Status,
  DoctorsTitle,
  DoctorsContainer,
  Doctor,
  FeedTitle,
  ModalContainer,
  ModalTitle,
  RadioButton,
  LineRadioButton,
  RadioButtonInput,
  RadioButtonLabel,
  ButtonContainer,
  CancelButtonContainer,
  CancelButton,
  ConfirmButtonContainer,
  ConfirmButton,
  DeleteContainer,
  DeleteWarningTitle,
  DeleteWarningText,
  ModalButtonsContainer,
  CancelModalButton,
  DeleteModalButton,
  LoaderContainer,
} from './SpecificPatient.elements';

import TimelineItem from './TimelineItem';

interface IPatientProps {
  name: string;
  status_id: number;
  status: string;
  doctors: any;
  id: string;
}
interface ParamTypes {
  patientUserId: string;
}

const SpecificPatient: React.FC = () => {
  const [loadingPatient, setLoadingPatient] = useState(false);
  const { patientUserId } = useParams<ParamTypes>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [patient, setPatient] = useState<IPatientProps>(() => {
    return {} as IPatientProps;
  });

  const [status, setStatus] = useState('');
  const [allStatus, setAllStatus] = useState<any[]>([]);
  const [feed, setFeed] = useState([]);

  const loadPatient = async () => {
    const res = await api.get(
      `/patients/${patientUserId}?simplifiedResult=true`,
    );
    if (res && res.data) {
      setPatient({
        id: res.data.id,
        name: res.data.name,
        status_id: res.data.status?.id,
        status: res.data.status?.name,
        doctors: res.data.patients_users,
      });
      setStatus(res.data.status?.id);
      setLoadingPatient(true);
    }
  };

  const loadAllStatus = async () => {
    const res = await api.get(`/patients-status`);
    if (res && res.data) {
      setAllStatus(res.data);
    }
  };

  const loadFeed = async () => {
    const res = await api.get(`/patients-update-patient-id/${patient.id}`);
    if (res && res.data) {
      const data = res.data.map((resData: any) => ({
        doctor: `${resData.user.name} ${resData.user.surname}`,
        date: resData.date,
        isSonhe: resData.user.isSonhe,
        description: resData.description,
      }));
      setFeed(data);
    }
  };

  const updateStatus = async () => {
    const res = await api.put(`/patients/${patient?.id}`, {
      status_id: status,
    });
    setTimeout(function () {
      if (res && res.data) {
        loadPatient();
      }
    }, 3000);
  };

  const deletePatient = async () => {
    await api.delete(`/patients/${patient.id}`);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow = 'visible';
    }
    history.push('/controle-de-pacientes');
  };

  const formatDate = (data: string): string => {
    const day = data.substring(8, 10) + ' ';
    const year = data.substring(0, 4);
    switch (data.substring(5, 7)) {
      case '01':
        return day + 'de Janeiro ' + year;
      case '02':
        return day + 'de Fevereiro ' + year;
      case '03':
        return day + 'de Março ' + year;
      case '04':
        return day + 'de Abril ' + year;
      case '05':
        return day + 'de Maio ' + year;
      case '06':
        return day + 'de Junho ' + year;
      case '07':
        return day + 'de Julho ' + year;
      case '08':
        return day + 'de Agosto ' + year;
      case '09':
        return day + 'de Setembro ' + year;
      case '10':
        return day + 'de Outubro ' + year;
      case '11':
        return day + 'de Novembro  ' + year;
      default:
        return day + 'de Dezembro  ' + year;
    }
  };

  useEffect(() => {
    loadAllStatus();
    loadPatient();
  }, []);

  useEffect(() => {
    if (patient.id) loadFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const openOrCloseDeleteModal = () => {
    setShowDeleteModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  return (
    <>
      {loadingPatient ? (
        <Container>
          {/* Header */}
          <HeaderContainer>
            <Title>{patient.name}</Title>
            <CaseMenu
              openModal={openOrCloseModal}
              openDeleteModal={openOrCloseDeleteModal}
            />
          </HeaderContainer>
          <SubtitleContainer>
            <StatusName>STATUS</StatusName>
            <Status>{patient.status}</Status>
          </SubtitleContainer>
          <DoctorsTitle> Medicos </DoctorsTitle>
          <DoctorsContainer>
            <Doctor
              key={0}
              zIndex={0}
              photo={patient.doctors[0]?.forwarder?.photo_address}
            />
            {patient.doctors?.map(
              (
                patientUser: { doctor: { photo_address: string } },
                index: any,
              ) =>
                patientUser.doctor.photo_address && (
                  <Doctor
                    key={index}
                    zIndex={index}
                    photo={patientUser.doctor?.photo_address}
                  />
                ),
            )}
          </DoctorsContainer>
          <Divisor width={100} />

          {/* Feed do Paciente */}
          <FeedTitle>Feed do Paciente</FeedTitle>
          {feed?.map(({ date, isSonhe, description, doctor }, index) => (
            <TimelineItem
              key={index}
              ind={index}
              numberOfItems={feed.length - 1}
              description={description}
              doctor={doctor}
              isSonhe={isSonhe}
              date={formatDate(date)}
              redirectTo="/em-construção"
            />
          ))}
        </Container>
      ) : (
        <LoaderContainer>
          <ThreeDots color={theme.color.mainBrown} height={100} width={100} />
        </LoaderContainer>
      )}

      {/* EditModal */}
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ModalContainer>
          <ModalTitle>Selecione o novo status</ModalTitle>
          <RadioButton>
            {allStatus?.map(({ name, id }) => (
              <LineRadioButton>
                <RadioButtonInput
                  type="radio"
                  checked={status === id}
                  value={id}
                  onChange={e => {
                    setStatus(e.target.value);
                  }}
                />
                <RadioButtonLabel>{name}</RadioButtonLabel>
              </LineRadioButton>
            ))}
          </RadioButton>
          <Divisor />
          <ButtonContainer>
            <CancelButtonContainer>
              <CancelButton onClick={openOrCloseModal}>Cancelar</CancelButton>
            </CancelButtonContainer>
            <ConfirmButtonContainer>
              <ConfirmButton
                onClick={() => {
                  openOrCloseModal();
                  updateStatus();
                  loadPatient();
                }}
              >
                Confirmar
              </ConfirmButton>
            </ConfirmButtonContainer>
          </ButtonContainer>
        </ModalContainer>
      </Modal>

      <Modal showModal={showDeleteModal} setShowModal={setShowDeleteModal}>
        <DeleteContainer>
          <DeleteWarningTitle>
            Você tem certeza que deseja apagar?
          </DeleteWarningTitle>
          <DeleteWarningText>
            {' '}
            Esta é uma ação irreversível. Tem certeza que deseja continuar?{' '}
          </DeleteWarningText>
          <ModalButtonsContainer>
            <CancelModalButton onClick={openOrCloseDeleteModal}>
              {' '}
              Cancelar{' '}
            </CancelModalButton>
            <DeleteModalButton onClick={deletePatient}>
              {' '}
              Apagar{' '}
            </DeleteModalButton>
          </ModalButtonsContainer>
        </DeleteContainer>
      </Modal>
    </>
  );
};

export default SpecificPatient;
