import React, { createRef } from 'react';
import { Search } from '../../assets/icons';
import { useFilter } from '../PatientsControl/DoctorPatients/FilterContext';
import {
  GlobalContainer,
  SearchBar,
  SearchContainer,
  IconContainer,
  OrderPatients,
} from './Search.elements';

interface SearchProps {
  placeHolder?: string;
}

const SearchPatients: React.FC<SearchProps> = ({ placeHolder }) => {
  const { changeOrder, changeSearchText } = useFilter();
  const searchInput = createRef<HTMLInputElement>();

  return (
    <>
      <GlobalContainer>
        <SearchContainer>
          <IconContainer>
            <Search></Search>
          </IconContainer>
          <SearchBar
            placeholder={placeHolder}
            ref={searchInput}
            onChange={changeSearchText}
          ></SearchBar>
        </SearchContainer>
        <OrderPatients onChange={changeOrder}>
          <option value="" selected>
            Ordenar por
          </option>
          <option value="Alphabetic">Ordem alfabética</option>
        </OrderPatients>
      </GlobalContainer>
    </>
  );
};

export default SearchPatients;
