import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  display: flex;
  max-width: 80%;
  min-width: 60%;
  align-items: center;
`;

export const CardsButton = styled.button`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 1rem;

  svg {
    height: 2.4rem;
    cursor: pointer;
    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;
