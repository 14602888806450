import React from 'react';
import api from '../../../services/api';

import {
  PatientName,
  PatientNumber,
  PatientForwardedBy,
  ReferralsContainer,
  TextContainer,
  PatientInfos,
  ForwardingContainer,
  ForwardingDate,
  ForwardedText,
  ButtonContainer,
} from './PatientsSonheLiga.elements';

import Button from '../../Button';

import PatientDivisor from '../PatientDivisor';

type infos = {
  callId: string;
  name: string;
  telephoneNumber: string;
  forwarder: string;
  date: string;
  callStatus: boolean;
  nameStatus: string;
  loadCalls: () => void;
};

const ReferralsPatient: React.FC<infos> = ({
  callId,
  loadCalls,
  name,
  telephoneNumber,
  forwarder,
  date,
  callStatus,
  nameStatus,
}) => {
  const isCalled = async () => {
    if (callStatus == true) {
      await api.put(`/sonhe-calls/${callId}`, {
        wasCalled: false,
      });
      loadCalls();
    } else {
      await api.put(`sonhe-calls/${callId}`, {
        wasCalled: true,
      });
      loadCalls();
    }
  };

  return (
    <>
      <ReferralsContainer>
        <TextContainer>
          <PatientInfos>
            <PatientName>{name}</PatientName>
            <PatientNumber>- {telephoneNumber}</PatientNumber>
          </PatientInfos>
          <ForwardingContainer>
            <ForwardedText>Encaminhado por</ForwardedText>
            <PatientForwardedBy>{forwarder} em</PatientForwardedBy>
            <ForwardingDate>{date}</ForwardingDate>
          </ForwardingContainer>
        </TextContainer>
        <ButtonContainer
          onClick={() => {
            isCalled();
          }}
        >
          <Button title={nameStatus} isActive={callStatus} />
        </ButtonContainer>
      </ReferralsContainer>
      <PatientDivisor />
    </>
  );
};

export default ReferralsPatient;
