import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SpecialtyInput = styled.input`
  width: 100%;
  border: none;
  font-size: 1.8rem;
  font-family: ${props => props.theme.font.middleText};
`;

export const SpecialtyLabel = styled.text`
  width: 100%;
  border: none;
  color: rgba(19, 19, 19, 0.6);
  font-size: 1.8rem;
  font-family: ${props => props.theme.font.middleText};
`;

export const SaveText = styled.text`
  font-size: 1.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.8rem;
  color: ${props => props.theme.color.green};
  font-family: ${props => props.theme.font.middleText};
`;

export const SaveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2rem;
  display: flex;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  color: ${props => props.theme.color.black};
  align-items: center;
`;

export const TrashContainer = styled.div`
  margin-left: 1.6rem;
`;
