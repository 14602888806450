/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef } from 'react';

import { ModalBody, ModalContainer } from './Modal.elements';

type WithChildren<T = unknown> = T & { children?: React.ReactNode };

interface IModalProps {
  showModal: any;
  setShowModal: any;
  children: WithChildren;
  otherPage?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  children,
  showModal,
  setShowModal,
  otherPage,
}) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      const body = document.querySelector('body');
      if (body !== null && (otherPage == false || otherPage == null)) {
        body.style.overflow === 'hidden'
          ? (body.style.overflow = 'visible')
          : (body.style.overflow = 'hidden');
      } else if (body != null && otherPage) {
        body.style.overflow === 'hidden'
          ? (body.style.overflow = 'hidden')
          : (body.style.overflow = 'visible');
      }
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: any) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <ModalBody modalActive={showModal} onClick={closeModal} ref={modalRef}>
          <ModalContainer>{children}</ModalContainer>
        </ModalBody>
      ) : null}
    </>
  );
};

export default Modal;
