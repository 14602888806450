import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 1.5rem;
  background-color: ${props => props.theme.color.white};
`;

export const CardsDoctor = styled.div`
  width: 100%;
  display: flex;
`;

export const CardsTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
  margin-bottom: 1rem;
`;

export const SeeMore = styled.p`
  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.blue};
  margin-top: 17px;
  margin-bottom: 5px;
  align-self: center;
  cursor: pointer;
`;
