import React, { FormEvent, useState } from 'react';

import {
  ErrorMessage,
  LoginButton,
  LoginFormContainer,
  LoginFormInputsContainer,
  LoginFormOptionsContainer,
} from './LoginForm.elements';

import LoginInput from '../LoginInput/LoginInput';

interface LoginFormProps {
  handle: (e: FormEvent, formData: { email: string; password: string }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleIsWrong: any;
  isWrong: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  handle,
  isWrong,
  handleIsWrong,
}: LoginFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <LoginFormContainer
      onSubmit={(e: FormEvent) => handle(e, { email, password })}
    >
      <LoginFormInputsContainer>
        <LoginInput
          placeholderName="Email"
          onChangeAction={setEmail}
          onFocusAction={handleIsWrong}
        />
        <LoginInput
          placeholderName="Senha"
          type="password"
          onChangeAction={setPassword}
          onFocusAction={handleIsWrong}
        />
      </LoginFormInputsContainer>
      {isWrong && <ErrorMessage>Email ou senha incorretos!</ErrorMessage>}
      <LoginFormOptionsContainer>
        <LoginButton type="submit">Login</LoginButton>
      </LoginFormOptionsContainer>
    </LoginFormContainer>
  );
};

export default LoginForm;
