import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';
interface ButtonProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLButtonElement>> {
  active?: boolean;
}

export const ButtonContainer = styled.button<ButtonProps>`
  width: 19rem;
  height: 5rem;
  color: ${props => {
    return props.active ? props.theme.color.green : props.theme.color.red;
  }};
  background-color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.mediumText};
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${props => {
    return props.active ? props.theme.color.green : props.theme.color.red;
  }};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => {
      return props.active ? props.theme.color.green : props.theme.color.red;
    }};
    color: ${props => props.theme.color.white};
    transition: background-color 0.1s ease-in;
  }
`;
