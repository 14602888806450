import { PhotosContainer, Doctor } from './PatientsWithContacts.elements';

import {
  PatientName,
  PatientNumber,
  PatientForwardedBy,
  ReferralsContainer,
  TextContainer,
  PatientInfos,
  ForwardingContainer,
  ForwardingDate,
  ForwardedText,
} from '../PatientsSonheLiga/PatientsSonheLiga.elements';

import PatientDivisor from '../PatientDivisor';
import Divisor from '../../Divisor';

type Infos = {
  name: string;
  telephoneNumber: string;
  forwarder: string;
  date: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  doctorPhoto: Array<string>;
};

const PatientsWithContact: React.FC<Infos> = ({
  name,
  telephoneNumber,
  forwarder,
  date,
  doctorPhoto,
}) => {
  return (
    <>
      <ReferralsContainer>
        <TextContainer>
          <PatientInfos>
            <PatientName>{name}</PatientName>
            <PatientNumber>- {telephoneNumber}</PatientNumber>
          </PatientInfos>
          <ForwardingContainer>
            <ForwardedText>Encaminhado por</ForwardedText>
            <PatientForwardedBy>{forwarder} em</PatientForwardedBy>
            <ForwardingDate>{date}</ForwardingDate>
          </ForwardingContainer>
        </TextContainer>
        <PhotosContainer>
          {doctorPhoto?.map((image, index) => (
            <Doctor photo={image} zIndex={index} />
          ))}
        </PhotosContainer>
      </ReferralsContainer>
      <PatientDivisor />
    </>
  );
};

export default PatientsWithContact;
