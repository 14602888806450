import styled from 'styled-components';

export const LoginFormContainer = styled.form`
  grid-area: loginFormContainer;
  display: grid;

  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto auto;
`;

export const LoginFormInputsContainer = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
`;

export const LoginFormOptionsContainer = styled.div``;

export const LoginButton = styled.button`
  margin-top: 4rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  width: 100%;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};

  &:hover {
    transition: 0.2s;
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;

export const ErrorMessage = styled.span`
  font: ${props => props.theme.font.smallText};
  color: #eb5757;
`;
