import styled from 'styled-components';
import React, { HTMLAttributes } from 'react';

interface DescriptionProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLParagraphElement>> {
  open?: boolean;
}

export const Container = styled.div`
  display: flex;
  margin-left: 8rem;
`;

export const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${props => props.theme.color.blue};
`;
export const BigLine = styled.div`
  width: 1px;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    ${props => props.theme.color.blue},
    ${props => props.theme.color.white}
  );
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateContainer = styled.div`
  background-color: ${props => props.theme.color.blue};
  width: 18.5rem;
  height: 8rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Date = styled.p`
  color: ${props => props.theme.color.white};
  font: ${props => props.theme.font.normalText};
`;

export const RightContainer = styled.div`
  margin-left: 1.3rem;
  max-width: 25rem;
  margin-bottom: 1rem;
`;

export const Title = styled.p`
  font: ${props => props.theme.font.normalText};
  color: ${props => props.theme.color.black};
`;

export const Description = styled.p<DescriptionProps>`
  font: ${props => props.theme.font.normalText};
  color: #636363;
  margin-top: 0.4rem;

  display: ${props => {
    return props.open ? '-webkit-box' : 'block';
  }};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ShowMore = styled.p`
  font: ${props => props.theme.font.normalText};
  color: #636363;

  text-decoration: none;
  cursor: pointer;
`;
