import React from 'react';
import { Input, Container, IconContainer } from './InputComponent.elements';
import { Search } from '../../../assets/icons/index';

type PlaceHolderProps = {
  placeHolder?: string;
  fillInput: (value: string) => void;
  caps?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  hidePassword?: boolean;
  autoCorrection?: boolean;
  valueToShow?: string;
  height?: string;
  font?: string;
  icon?: boolean;
};

const InputCard: React.FC<PlaceHolderProps> = ({
  placeHolder,
  caps,
  autoCorrection,
  hidePassword,
  valueToShow,
  height,
  font,
  icon,
  fillInput,
}) => {
  const handleChange = (text: string) => {
    fillInput(text);
  };
  return (
    <Container>
      {icon ? (
        <IconContainer>
          <Search></Search>
        </IconContainer>
      ) : null}
      <Input
        value={valueToShow}
        placeholder={placeHolder}
        autoCapitalize={caps}
        autoCorrect={autoCorrection ? 'on' : 'off'}
        type={hidePassword ? 'password' : 'text'}
        onChange={(e: any) => handleChange(e.target.value)}
        height={height}
        font={font}
        icon={icon}
      />
    </Container>
  );
};

export default InputCard;
