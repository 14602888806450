import styled from 'styled-components';

interface ModalProps {
  block?: boolean;
  color?: string;
}

export const Container = styled.div<ModalProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  width: 100%;
  max-height: 4rem;
  padding: 1.5rem;
  &:hover {
    background-color: ${props =>
      props.block ? props.theme.color.white : props.theme.color.whiteHover};
  }
`;

export const GreyContainer = styled.div`
  width: 90%;
  max-height: 100%;
  padding: 1.5rem;
  background-color: ${props => props.theme.color.white};
  position: absolute;
  opacity: 0.5;
`;

export const OptionTitle = styled.p<ModalProps>`
  font: ${props => props.theme.font.middleText};
  color: ${props => (props.color ? props.color : props.theme.color.blue)};
  margin-left: 2rem;
`;

export const IconContainer = styled.div`
  width: 2rem;
`;
