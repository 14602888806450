import React, { useState } from 'react';
import {
  CardContainer,
  CardDescriptionContainer,
  CardDescriptionText,
  CardHeaderContainer,
  CardListItem,
  CardListItemContainer,
  CardListItemImage,
  CardListItemTitle,
  CardListTitle,
  CardMenuContainer,
  CardMenuPinIconContainer,
  CardModalContainer,
  CardSubtitle,
  CardTitle,
  CardTitleContainer,
  CardTitleImage,
} from './CardItem.elements';

import {
  Edit,
  RedTrash,
  Pin,
  FrameDown,
  FrameUp,
  GreyPin,
  ThreeDots,
  TrendingDown,
} from '../../../../assets/icons';

import {
  DenyCard,
  MenuCard,
  Modal,
  AddServicesCard,
} from '../../../../components';
import AddInsuranceCard from '../../../../components/AddInsuranceCard';

interface InsuranceListProps {
  image?: string;
  title: string;
}

interface CardItemProps {
  itemId: string;
  isService?: boolean;
  lastItem: boolean;
  firstItem: boolean;
  fixedTop: boolean;
  cardTitle: string;
  cardTitleImage?: string;
  cardSubtitle?: string;
  cardListItems: any[];
  cardDescriptionTexts?: {
    streetAddress: string;
    region: string;
    cep: string;
  };
  cardListTitle: string;
  deleteAction?: any;
  orderItemFunc: (itemId: string, orderUp: boolean, endOfList: boolean) => void;
  fixedItemFunc: (itemId: string) => void;
  unpinItemFunc: () => void;
  reloadItems?: () => void;
}

const CardItem: React.FC<CardItemProps> = ({
  isService,
  fixedTop,
  lastItem,
  firstItem,
  cardTitle,
  cardTitleImage,
  cardSubtitle,
  cardListItems,
  cardDescriptionTexts,
  cardListTitle,
  deleteAction,
  itemId,
  orderItemFunc,
  fixedItemFunc,
  unpinItemFunc,
  reloadItems,
}: CardItemProps) => {
  const [modal, setModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <CardContainer>
        <CardHeaderContainer>
          {isService ? (
            <CardTitleContainer isService={isService}>
              <CardTitle>{cardTitle}</CardTitle>
              <CardSubtitle>{cardSubtitle}</CardSubtitle>
            </CardTitleContainer>
          ) : (
            <CardTitleContainer isService={isService}>
              <CardTitleImage src={cardTitleImage} />
              <CardTitle>{cardTitle}</CardTitle>
            </CardTitleContainer>
          )}
          <CardMenuContainer>
            <CardMenuPinIconContainer>
              {fixedTop && <GreyPin />}
            </CardMenuPinIconContainer>
            <ThreeDots
              onClick={() => setModal(!modal)}
              style={{ cursor: 'pointer' }}
            />
            {modal && (
              <CardModalContainer>
                <MenuCard
                  options={[
                    {
                      title: 'Editar serviço',
                      icon: Edit,
                      action: () => {
                        setShowEditModal(!showEditModal);
                      },
                    },
                    fixedTop
                      ? {
                          title: 'Desfixar do topo',
                          icon: Pin,
                          action: unpinItemFunc,
                        }
                      : {
                          title: 'Fixar no topo',
                          icon: Pin,
                          action: () => {
                            fixedItemFunc(itemId);
                            setModal(false);
                          },
                        },
                    {
                      title: 'Avançar',
                      icon: FrameUp,
                      block: fixedTop || firstItem,
                      action: () => {
                        orderItemFunc(itemId, true, false);
                        setModal(false);
                      },
                    },
                    {
                      title: 'Recuar',
                      icon: FrameDown,
                      block: fixedTop || lastItem,
                      action: () => {
                        orderItemFunc(itemId, false, false);
                        setModal(false);
                      },
                    },
                    {
                      title: 'Levar para final da lista',
                      icon: TrendingDown,
                      block: fixedTop || lastItem,
                      action: () => orderItemFunc(itemId, false, true),
                    },
                    {
                      title: 'Apagar',
                      icon: RedTrash,
                      color: '#C60000',
                      action: () => setShowDenyModal(!showDenyModal),
                    },
                  ]}
                  width="26rem"
                />
              </CardModalContainer>
            )}
          </CardMenuContainer>
        </CardHeaderContainer>
        {isService && (
          <CardDescriptionContainer>
            <CardDescriptionText>
              {cardDescriptionTexts?.streetAddress}
            </CardDescriptionText>
            <CardDescriptionText>
              {cardDescriptionTexts?.region}
            </CardDescriptionText>
            <CardDescriptionText>{`CEP: ${cardDescriptionTexts?.cep}`}</CardDescriptionText>
          </CardDescriptionContainer>
        )}
        {cardListItems.length > 0 && (
          <CardListTitle>{cardListTitle}</CardListTitle>
        )}
        <CardListItemContainer>
          {cardListItems?.map(
            (cardListItem: InsuranceListProps, index: number) => (
              <CardListItem key={index}>
                {isService && <CardListItemImage src={cardListItem?.image} />}

                <CardListItemTitle>{cardListItem.title}</CardListItemTitle>
              </CardListItem>
            ),
          )}
        </CardListItemContainer>
      </CardContainer>
      <Modal showModal={showDenyModal} setShowModal={setShowDenyModal}>
        <DenyCard
          title={
            isService
              ? 'Tem certeza que deseja negar esse serviço?'
              : 'Tem certeza que deseja negar esse convênio?'
          }
          description="Esta é uma ação irreversível. Tem certeza que deseja continuar?"
          rightButtonTitle="Apagar"
          leftButtonFunction={() => setShowDenyModal(!showDenyModal)}
          rightButtonFunction={() => {
            deleteAction();
            setShowDenyModal(!showDenyModal);
            setModal(!modal);
          }}
        />
      </Modal>
      <Modal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        otherPage
      >
        {isService ? (
          <AddServicesCard
            type="edit"
            serviceId={itemId}
            leftButtonFunction={() => setShowEditModal(!showEditModal)}
            rightButtonFunction={() => {
              setShowEditModal(!showEditModal);
            }}
          />
        ) : (
          <AddInsuranceCard
            type="edit"
            insuranceId={itemId}
            leftButtonFunction={() => setShowEditModal(!showEditModal)}
            rightButtonFunction={() => {
              setShowEditModal(!showEditModal);
              if (reloadItems != undefined) {
                reloadItems();
              }
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default CardItem;
