import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
`;

export const Container = styled.div`
  padding-top: 2.6rem;
  padding-bottom: 5rem;
  background-color: ${props => props.theme.color.backgroundGray};
`;
export const CardsContainer = styled.div`
  margin: 0 auto;
  max-width: 56%;
  padding: 2rem;
  padding-top: 2.4rem;
  border-radius: 0.5rem;
  position: initial;
  z-index: 0;
  background-color: ${props => props.theme.color.backgroundGray};
`;

export const EmptyList = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  position: relative;
  text-align: center;
  margin: 6rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
