import React from 'react';
import {
  Container,
  OptionTitle,
  IconContainer,
  GreyContainer,
} from './MenuOptions.elements';
import { useHistory } from 'react-router-dom';

interface MenuOptionsProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  redirectTo?: string;
  icon: React.ComponentType;
  block?: boolean;
  color?: string;
  action?: any;
}

const MenuOptions: React.FC<MenuOptionsProps> = ({
  icon: Component,
  title,
  redirectTo,
  block,
  color,
  action,
}) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const redirect = () => {
    redirectTo && history.push(redirectTo);
  };
  return (
    <>
      <Container block={block} onClick={action}>
        <IconContainer>{Component}</IconContainer>
        <OptionTitle color={color}>{title}</OptionTitle>
        {block ? <GreyContainer></GreyContainer> : null}
      </Container>
    </>
  );
};

export default MenuOptions;
