import React, { useRef, useEffect } from 'react';

import { Divisor } from '../index';

import {
  DenyContainer,
  DenyWarningTitle,
  DenyWarningText,
  ButtonsContainer,
  DenyModalButton,
  CancelModalButton,
} from './DenyCard.elements';

type DenyCardProps = {
  title: string;
  description: string;
  rightButtonTitle: string;
  leftButtonFunction?: () => void;
  rightButtonFunction?: () => void;
};

const DenyCard: React.FC<DenyCardProps> = ({
  title,
  description,
  rightButtonTitle,
  leftButtonFunction,
  rightButtonFunction,
}) => {
  return (
    <DenyContainer>
      <DenyWarningTitle>{title}</DenyWarningTitle>
      <DenyWarningText>{description}</DenyWarningText>
      <Divisor width={100} />
      <ButtonsContainer>
        <CancelModalButton onClick={leftButtonFunction}>
          Cancelar
        </CancelModalButton>
        <DenyModalButton onClick={rightButtonFunction}>
          {rightButtonTitle}
        </DenyModalButton>
      </ButtonsContainer>
    </DenyContainer>
  );
};

export default DenyCard;
