import React from 'react';

import { CardsContainer, CardsTitle, CardsDoctor } from './Cards.elements';

import { PatientDivisor } from '../../Referrals';

type CardsProps = { title?: string; doctor?: any };

const Card: React.FC<CardsProps> = ({ title, doctor, children }) => {
  if (doctor) {
    title = '';
  }
  return (
    <CardsContainer>
      <CardsDoctor>{doctor}</CardsDoctor>
      <CardsTitle>{title}</CardsTitle>
      {children}
    </CardsContainer>
  );
};

export default Card;
