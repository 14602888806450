import React, { FormEvent, useState } from 'react';
import {
  GridContainer,
  LoginContainer,
  LoginTitle,
  LogoContainer,
  Container,
} from './Login.elements';

import EmailValidator from 'email-validator';

import { LogoLogin } from '../../assets/icons';
import { useHistory } from 'react-router-dom';
import LoginForm from './LoginForm/LoginForm';
import { useAuth } from '../../hooks/auth';
import { useSocket } from '../../hooks/socket';
interface LoginFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const { setupSocket } = useSocket();
  const { signIn } = useAuth();
  const [isWrong, setIsWrong] = useState(false);

  const handleSubmit = async (e: FormEvent, formData: LoginFormData) => {
    try {
      e.preventDefault();
      const requiredFields = ['email', 'password'];

      for (const field of requiredFields) {
        if (!formData[field as keyof LoginFormData]) {
          setIsWrong(true);
          return;
        }
      }

      const { email, password } = formData;

      const isEmailValid = EmailValidator.validate(email);
      if (isEmailValid === false) {
        setIsWrong(true);
        return;
      }

      setupSocket();

      await signIn({ email, password });

      history.push('/');
    } catch (error) {
      setIsWrong(true);
    }
  };

  return (
    <Container>
      <GridContainer>
        <LogoContainer>
          <LogoLogin />
        </LogoContainer>
        <LoginContainer>
          <LoginTitle>Login - SOnHe Admin</LoginTitle>
          <LoginForm
            handle={handleSubmit}
            isWrong={isWrong}
            handleIsWrong={setIsWrong}
          />
        </LoginContainer>
      </GridContainer>
    </Container>
  );
};

export default Login;
