import React from 'react';
import { ButtonContainer } from './Button.elements';

interface ButtonProps {
  title?: string;
  isActive?: boolean;
}

const Button: React.FC<ButtonProps> = ({ title, isActive }) => {
  return (
    <>
      <ButtonContainer active={isActive}>{title}</ButtonContainer>
    </>
  );
};
export default Button;
