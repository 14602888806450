import React, { Dispatch, SetStateAction } from 'react';
import { Search } from '../../../../assets/icons';
import { SearchBar } from '../../../../components';
import {
  HeaderContainer,
  IconContainer,
  CardsButton,
  SearchContainer,
} from './HeaderSearch.elements';

interface HeaderSearchProps {
  searchBarPlaceholder: string;
  buttonTitle: string;
  onClickButton: () => void;
  onChangeText: Dispatch<SetStateAction<string>>;
}

const HeaderSearch: React.FC<HeaderSearchProps> = ({
  searchBarPlaceholder,
  buttonTitle,
  onClickButton,
  onChangeText,
}: HeaderSearchProps) => (
  <HeaderContainer>
    <SearchContainer>
      <IconContainer>
        <Search />
      </IconContainer>
      <SearchBar
        placeholder={searchBarPlaceholder}
        onChangeFunction={onChangeText}
      ></SearchBar>
    </SearchContainer>
    <CardsButton onClick={onClickButton}>{buttonTitle}</CardsButton>
  </HeaderContainer>
);

export default HeaderSearch;
