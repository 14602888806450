import React, { createContext, useCallback, useContext, useState } from 'react';
import io from 'socket.io-client';
import { useEffect } from 'react';

interface SocketContextProps {
  socket: SocketIOClient.Socket | null;
  setupSocket(): Promise<void>;
}

const SocketContext = createContext<SocketContextProps>(
  {} as SocketContextProps,
);

export const SocketProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

  const setupSocket = useCallback(async () => {
    const token = localStorage.getItem('@SOnHeAdmin:token');

    if (token && !socket) {
      const newSocket = io('https://api.sonhedigital.com.br/');
      newSocket.on('disconnect', async () => {
        setSocket(null);
        setTimeout(setupSocket, 3000);
      });

      setSocket(newSocket);
    }
  }, [socket]);

  useEffect(() => {
    setupSocket();
  }, [setupSocket]);
  return (
    <SocketContext.Provider value={{ socket: socket, setupSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextProps => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context;
};
