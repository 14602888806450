import React from 'react';
import { Container, OptionTitle, IconContainer } from './MenuOptions.elements';
import { useHistory } from 'react-router-dom';

interface MenuOptionsProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  redirectTo?: string;
  icon: React.ComponentType;
}

const MenuOptions: React.FC<MenuOptionsProps> = ({
  icon: Component,
  title,
  redirectTo,
}) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const redirect = () => {
    redirectTo && history.push(redirectTo);
  };
  return (
    <>
      <Container>
        <IconContainer>{Component}</IconContainer>
        <OptionTitle>{title}</OptionTitle>
      </Container>
    </>
  );
};

export default MenuOptions;
