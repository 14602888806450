import styled from 'styled-components';

export const Container = styled.div``;
export const Wrapper = styled.div`
  width: 100px;
  max-height: 80vh;
  overflow: auto;
`;

export const DropDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-width: 25rem;
  gap: 2.4rem;

  user-select: none;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
`;

export const Triangle = styled.div`
  position: relative;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;

  border-bottom: 13px solid white;
  margin-left: 1.6rem;
`;
