import React from 'react';
import {
  Container,
  OptionTitleAndImageContainer,
  OptionImage,
  OptionTitle,
} from './AdditionOptions.elements';

import { XBlue } from '../../../assets/icons/index';

type AdditionOptionsProps = {
  title: string;
  image?: string;
  deleteFunction: () => void;
};

const AdditionOptions: React.FC<AdditionOptionsProps> = ({
  title,
  image,
  deleteFunction,
}) => {
  return (
    <>
      <Container>
        <OptionTitleAndImageContainer>
          {(image !== undefined || '' || null) && <OptionImage src={image} />}
          <OptionTitle>{title}</OptionTitle>
        </OptionTitleAndImageContainer>
        <XBlue
          onClick={deleteFunction}
          style={{
            cursor: 'pointer',
            alignSelf: 'center',
            width: '1.2rem',
            height: '1.2rem',
            marginTop: '0.1rem',
          }}
        />
      </Container>
    </>
  );
};
export default AdditionOptions;
