import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface CardTitleProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  isService?: boolean;
}

export const CardContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    'cardHeaderContainer'
    'cardDescriptionContainer'
    'cardListTitle'
    'cardListItemContainer';
  grid-gap: 1rem;
`;

export const CardHeaderContainer = styled.div`
  grid-area: cardHeaderContainer;
  display: flex;
  justify-content: space-between;
`;

export const CardMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardModalContainer = styled.div`
  position: absolute;
  margin-right: 10rem;
`;

export const CardMenuPinIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 0.8rem;

  background-color: ${props => props.theme.color.inputBorder};
  width: 2.5rem;
  border-radius: 50%;
  svg {
    height: 2.4rem;
    cursor: pointer;
    path {
      fill: ${props => props.theme.color.blue};
      fill-opacity: 1;
    }
  }
`;

export const CardTitleContainer = styled.div<CardTitleProps>`
  display: flex;
  flex-direction: ${props => (props.isService ? 'column' : 'row')};
  align-items: ${props => (props.isService ? 'inital' : 'center')};
`;

export const CardTitleImage = styled.img`
  border: 1px solid ${props => props.theme.color.inputBorder};
  max-width: 8rem;
  margin-right: 1.6rem;
`;

export const CardTitle = styled.h1`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};

  margin-bottom: 0.5rem;
`;

export const CardSubtitle = styled.h1`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
`;

export const CardDescriptionContainer = styled.div`
  grid-area: cardDescriptionContainer;
`;

export const CardDescriptionText = styled.h3`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};

  line-height: 2rem;
`;

export const CardDivisorContainer = styled.div`
  width: 100%;
  margin: 1.6rem 0 1.6rem 0;
`;

export const CardListTitle = styled.h1`
  grid-area: cardListTitle;
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
`;

export const CardInsuranceListTitle = styled.h1`
  grid-area: cardListTitle;
  font: ${props => props.theme.font.featuredMiddleText};
  color: ${props => props.theme.color.black};
`;

export const CardListItemContainer = styled.div`
  grid-area: cardListItemContainer;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const CardListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const CardListItemImage = styled.img`
  height: 2.1rem;
  width: 3.532rem;
  margin-right: 0.72rem;
  object-fit: contain;
`;

export const CardListItemTitle = styled.h3`
  font: ${props => props.theme.font.text};
  color: ${props => props.theme.color.black};
  line-height: 2rem;
`;
