export default {
  color: {
    red: '#EB5757',
    green: '#27AE60',
    white: '#FFFFFF',
    black: '#131313',
    blue: '#1F3249',
    mainBrown: '#846550',
    secondaryBrown: '#A78B6B',
    hoverBrown: '#9D826F',
    gray: '#D1D3D4',
    secondaryGray: '#E6E6F3',
    backgroundGray: '#E5E5E5',
    whiteHover: '#F0F0F0',
    inputBorder: '#C9C9C9',
    modalGrey: '#FAFAFA',
  },

  font: {
    bigTitle: 'normal normal bold 2.4rem "Roboto", sans-serif',
    smashTitle: 'normal normal bold 2.8rem "Roboto", sans-serif',
    bigText: 'normal normal bold 3.2rem "Roboto", sans-serif', // Roboto bold 32px: For titles
    mediumText: 'normal normal bold 1.8rem "Roboto", sans-serif', // Roboto bold 18px: For subtitles and other featured texts
    middleText: 'normal normal 1.8rem "Roboto", sans-serif',
    normalText: 'normal normal normal 1.6rem "Roboto", sans-serif', // Roboto regular 16px: For navbar, placeholder's and some components description
    navbarText: 'normal normal normal 1.5rem "Roboto", sans-serif',
    featuredNormalText: 'normal normal bold 1.6rem "Roboto", sans-serif', // Roboto regular 16px: For secondary featured texts, like doctor name on "Meus pacientes" component
    featuredMiddleText: 'normal normal bold 1.8rem "Roboto", sans-serif',
    text: 'normal normal normal 1.4rem "Roboto", sans-serif', // Roboto regular 14px: For body texts
    featuredText: 'normal normal bold 1.4rem "Roboto", sans-serif', // Roboto regular 14px: For featured body texts
    smallText: 'normal normal normal 1.2rem "Roboto", sans-serif', // Roboto regular 12px: For main cards descriptions Ex: Dar feedback ao encaminhador,
  },
};
