import React from 'react';

import { SearchInput } from './SearchBar.elements';

interface SearchBarInterface {
  onChangeFunction?: any;
  placeholder?: string;
}

const SearchBar: React.FC<SearchBarInterface> = ({
  onChangeFunction,
  placeholder,
}) => {
  return (
    <SearchInput
      type="text"
      placeholder={`${placeholder ?? 'Buscar pacientes'}`}
      onChange={e => onChangeFunction(e.target.value)}
    ></SearchInput>
  );
};

export default SearchBar;
