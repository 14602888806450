import styled from 'styled-components';

export const Container = styled.div`
  width: 72.6rem;
  padding-top: 3.5rem;
  padding-bottom: 4rem;
  padding-right: 2.6rem;
  padding-left: 2.6rem;
  background-color: ${props => props.theme.color.modalGrey};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font: ${props => props.theme.font.bigTitle};
  color: ${props => props.theme.color.black};
  margin-bottom: 3.2rem;
`;

export const ErrorContainer = styled.div`
  display: flex;
  margin-top: 0.8rem;
  align-items: center;
  gap: 0.8rem;
`;

export const ErrorMessage = styled.p`
  color: #c60000;
  font: ${props => props.theme.font.text};
`;

export const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  align-self: center;
  gap: 3.2rem;
`;

export const Button = styled.button`
  height: 5rem;
  width: 29.4rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;

export const CancelButton = styled.button`
  height: 5rem;
  width: 29.4rem;
  padding: 1rem 1.5rem;
  boheight: 5rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  border: 1px solid ${props => props.theme.color.mainBrown};
  font: ${props => props.theme.font.featuredNormalText};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.mainBrown};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
  }
`;
