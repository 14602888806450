import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridContainer = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logoContainer'
    'loginContainer';
  max-width: 56%;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  grid-area: logoContainer;
`;

export const LoginContainer = styled.div`
  grid-area: loginContainer;
  display: grid;
  min-width: 40rem;
  grid-row-gap: 2rem;
  grid-template-rows: 3rem auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'loginTitleContainer'
    'loginFormContainer';
  background-color: #fafafa;
  padding: 2.4rem;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
`;

export const LoginTitle = styled.h1`
  grid-area: loginTitleContainer;
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};

  justify-self: center;
`;
