import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Loader, { ThreeDots } from 'react-loader-spinner';
import theme from '../../assets/global/index';

import {
  CardsContainer,
  Wrapper,
  EmptyList,
  LoaderContainer,
} from './Referrals.elements';

import {
  ReferralsPatient,
  Card,
  PatientsWithContact,
  Divisor,
} from '../../components';

interface SonheLigaProps {
  id: string;
  name: string;
  number: string;
  sendBy: string;
  sendBySurname: string;
  date: string;
  call: boolean;
}

interface SonheContactProps {
  id: string;
  name: string;
  number: string;
  sendBy: string;
  sendBySurname: string;
  date: string;
  photoForwarder: string;
  photoDoctor: string;
  sonheCalls: [];
}

const Referrals: React.FC = () => {
  const { ...authInfo } = useAuth();
  const [loadingCalls, setLoadingCalls] = useState(false);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const putStatusName = (callStatus: boolean) => {
    if (callStatus === true) {
      return 'Ligação Feita';
    } else {
      return 'Aguardando ligação';
    }
  };

  const formatDate = (data: string): string => {
    const day = data.substring(8, 10) + ' ';
    switch (data.substring(5, 7)) {
      case '01':
        return day + 'Jan';
      case '02':
        return day + 'Fev';
      case '03':
        return day + 'Mar';
      case '04':
        return day + 'Abr';
      case '05':
        return day + 'Mai';
      case '06':
        return day + 'Jun';
      case '07':
        return day + 'Jul';
      case '08':
        return day + 'Ago';
      case '09':
        return day + 'Set';
      case '10':
        return day + 'Out';
      case '11':
        return day + 'Nov';
      default:
        return day + 'Dez';
    }
  };

  const [calls, setCalls] = useState<SonheLigaProps[]>(() => {
    return [] as SonheLigaProps[];
  });
  const [patients, setPatients] = useState<SonheContactProps[]>(() => {
    return [] as SonheContactProps[];
  });

  const loadCalls = async () => {
    const res = await api.get('/sonhe-calls');

    if (res && res.data) {
      const data = res.data.map(
        (callData: {
          id: string;
          patient: { name: string; forwarder: string; phone: string };
          user: { name: string; surname: string };
          created_at: string;
          wasCalled: boolean;
        }) => ({
          id: callData.id,
          name: callData.patient.name,
          number: callData.patient.phone,
          sendBy: callData.user.name,
          sendBySurname: callData.user?.surname,
          date: callData.created_at,
          call: callData.wasCalled,
        }),
      );
      setCalls(data);
      setLoadingCalls(true);
    }
  };

  const loadPatients = async () => {
    const res = await api.get(`/patients-users/?sonheCalls=true`);
    if (res && res.data) {
      const data = res.data.map((patient: any) => ({
        id: patient.id,
        name: patient.patient.name,
        number: patient.patient.phone,
        date: patient.created_at,
        sendBy: patient.forwarder?.name,
        sendBySurname: patient.forwarder?.surname,
        photoForwarder: patient.forwarder?.photo_address,
        photoDoctor: patient.doctor?.photo_address,
        sonheCalls: patient.patient.patients_sonhe_calls,
      }));

      setPatients(data);
      setLoadingPatients(true);
    }
  };

  useEffect(() => {
    loadPatients();
    loadCalls();
  }, []);

  return (
    <>
      <CardsContainer>
        <Card title="SOnHe Liga" grid-area="card1">
          <Divisor width={100} />
          {loadingCalls ? (
            <Wrapper>
              {calls?.map(
                ({ name, number, sendBy, sendBySurname, id, date, call }) => (
                  <ReferralsPatient
                    callId={id}
                    loadCalls={loadCalls}
                    key={id}
                    name={name}
                    telephoneNumber={number}
                    forwarder={sendBy + ' ' + sendBySurname}
                    date={formatDate(date)}
                    callStatus={call}
                    nameStatus={putStatusName(call)}
                  />
                ),
              )}
              {!(calls.length > 0) && (
                <EmptyList> Sem ligações em aguardo </EmptyList>
              )}
            </Wrapper>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
        <Card title="Pacientes com o contato do SOnHe" grid-area="card2">
          <Divisor width={100} />
          {loadingPatients ? (
            <Wrapper>
              {patients?.map(
                ({
                  name,
                  number,
                  sendBy,
                  sendBySurname,
                  id,
                  date,
                  photoDoctor,
                  photoForwarder,
                  sonheCalls,
                }) =>
                  !sonheCalls?.length && (
                    <PatientsWithContact
                      key={id}
                      name={name}
                      telephoneNumber={number}
                      forwarder={sendBy + ' ' + sendBySurname}
                      date={formatDate(date)}
                      doctorPhoto={[photoForwarder, photoDoctor]}
                    />
                  ),
              )}
              {!(patients.length > 0) && <EmptyList> Sem pacientes </EmptyList>}
            </Wrapper>
          ) : (
            <LoaderContainer>
              <ThreeDots
                color={theme.color.mainBrown}
                height={100}
                width={100}
              />
            </LoaderContainer>
          )}
        </Card>
      </CardsContainer>
    </>
  );
};

export default Referrals;
