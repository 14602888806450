import styled from 'styled-components';

export const DenyContainer = styled.div`
  width: 59rem;
  padding: 2.5rem;
  background-color: ${props => props.theme.color.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const DenyWarningTitle = styled.p`
  font: ${props => props.theme.font.bigTitle};
  color: ${props => props.theme.color.blue};
`;

export const DenyWarningText = styled.p`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  align-self: center;
  gap: 5.4rem;
`;

export const DenyModalButton = styled.button`
  height: 5rem;
  width: 18.7rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;

export const CancelModalButton = styled.button`
  height: 5rem;
  width: 18.7rem;
  padding: 1rem 1.5rem;
  boheight: 5rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  border: 1px solid ${props => props.theme.color.mainBrown};
  font: ${props => props.theme.font.featuredNormalText};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.mainBrown};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
  }
`;
