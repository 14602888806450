import React, { useState } from 'react';
import api from '../../../services/api';

import {
  ThreeDotsContainer,
  DoctorFirstPart,
  ActionContainer,
  DropDownMenu,
  DoctorListContainer,
  DoctorMainTextContainer,
  DoctorSecondTextContainer,
  DoctorFirstTextContainerModal,
  DoctorSecondTextContainerModal,
  DoctorName,
  DoctorCRM,
  DoctorSpecialty,
  DoctorEmail,
  DoctorNameModal,
  DoctorCRMModal,
  DoctorSpecialtyModal,
  DoctorEmailModal,
  DoctorImageContainer,
  DoctorImageContainerModal,
  DoctorImage,
  DoctorImageModal,
  EditRegistrationContainer,
  ButtonContainer,
  CancelButtonContainer,
  ConfirmButtonContainer,
  ModalTitle,
  CancelButton,
  ConfirmButton,
  RadioButton,
  LineRadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from './Doctor.elements';

import { DenyCard } from '../../index';

import Modal from '../../Modal';
import MenuOptions from '../../MenuOptions';
import DoctorDivisor from '../DoctorDivisor';
import { ThreeDots, Edit, Trash } from '../../../assets/icons';

type infos = {
  userId: string;
  doctorImage: string;
  doctorName: string;
  CRM: number;
  doctorSpecialty: string;
  doctorEmail: string;
  sonheUser: boolean;
  deleteUser: () => void;
  loadUser: () => void;
};

const Doctor: React.FC<infos> = ({
  userId,
  doctorImage,
  doctorName,
  doctorSpecialty,
  doctorEmail,
  CRM,
  sonheUser,
  deleteUser,
  loadUser,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [radio, setRadio] = useState(sonheUser);
  const isSonheUser = async () => {
    await api.put(`/users/${userId}`, {
      isSonhe: radio,
    });
    loadUser();
  };
  const openOrCloseModal = () => {
    setShowModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  const openOrCloseDenyModal = () => {
    setShowDenyModal(prev => !prev);
    const body = document.querySelector('body');
    if (body !== null) {
      body.style.overflow === 'hidden'
        ? (body.style.overflow = 'visible')
        : (body.style.overflow = 'hidden');
    }
  };

  return (
    <>
      <DoctorListContainer>
        <DoctorFirstPart>
          <DoctorImageContainer>
            <DoctorImage DImage={doctorImage}></DoctorImage>
          </DoctorImageContainer>
          <DoctorMainTextContainer>
            <DoctorName>{doctorName}</DoctorName>
            <DoctorCRM>CRM: {CRM}</DoctorCRM>
            <br />
          </DoctorMainTextContainer>
          <ThreeDotsContainer onClick={() => setOpen(!open)}>
            <ThreeDots />
            {open && (
              <DropDownMenu>
                <ActionContainer onClick={openOrCloseModal}>
                  <MenuOptions title="Editar Cadastro" icon={Edit} />
                </ActionContainer>
                <ActionContainer onClick={openOrCloseDenyModal}>
                  <MenuOptions title="Apagar" icon={Trash} />
                </ActionContainer>
              </DropDownMenu>
            )}
          </ThreeDotsContainer>
        </DoctorFirstPart>
        <DoctorSecondTextContainer>
          <DoctorSpecialty>{doctorSpecialty}</DoctorSpecialty>
          <DoctorEmail>{doctorEmail}</DoctorEmail>
        </DoctorSecondTextContainer>
      </DoctorListContainer>
      <DoctorDivisor />
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <EditRegistrationContainer>
          <ModalTitle>Editar Cadastro</ModalTitle>
          <DoctorImageContainerModal>
            <DoctorImageModal DImage={doctorImage}></DoctorImageModal>
          </DoctorImageContainerModal>
          <DoctorFirstPart>
            <DoctorFirstTextContainerModal>
              <DoctorNameModal>{doctorName}</DoctorNameModal>
              <DoctorCRMModal>CRM: {CRM}</DoctorCRMModal>
              <br />
            </DoctorFirstTextContainerModal>
            <RadioButton>
              <LineRadioButton>
                <RadioButtonInput
                  type="radio"
                  checked={!radio}
                  value="Encaminhador"
                  onChange={() => {
                    setRadio(!radio);
                  }}
                />
                <RadioButtonLabel>Encaminhador</RadioButtonLabel>
              </LineRadioButton>
              <LineRadioButton>
                <RadioButtonInput
                  type="radio"
                  checked={radio}
                  value="MedicoSOnHe"
                  onChange={() => setRadio(!radio)}
                />
                <RadioButtonLabel>Médico SOnHe</RadioButtonLabel>
              </LineRadioButton>
            </RadioButton>
          </DoctorFirstPart>
          <DoctorSecondTextContainerModal>
            <DoctorSpecialtyModal>{doctorSpecialty}</DoctorSpecialtyModal>
            <DoctorEmailModal>{doctorEmail}</DoctorEmailModal>
          </DoctorSecondTextContainerModal>
          <DoctorDivisor />
          <ButtonContainer>
            <CancelButtonContainer>
              <CancelButton onClick={openOrCloseModal}>Cancelar</CancelButton>
            </CancelButtonContainer>
            <ConfirmButtonContainer>
              <ConfirmButton
                onClick={() => {
                  isSonheUser();
                  openOrCloseModal();
                }}
              >
                Confirmar
              </ConfirmButton>
            </ConfirmButtonContainer>
          </ButtonContainer>
        </EditRegistrationContainer>
      </Modal>
      <Modal showModal={showDenyModal} setShowModal={setShowDenyModal}>
        <DenyCard
          title="Você tem certeza que deseja apagar esse usuário?"
          description="Esta é uma ação irreversível. Tem certeza que deseja continuar?"
          rightButtonTitle="Apagar"
          leftButtonFunction={openOrCloseDenyModal}
          rightButtonFunction={() => {
            openOrCloseDenyModal();
            deleteUser();
          }}
        />
      </Modal>
    </>
  );
};
export default Doctor;
