import styled from 'styled-components';

export const CardsContainer = styled.div`
  max-width: 56%;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-areas:
    'card1'
    'card2';
  grid-gap: 3.2rem;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyList = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.black};
  position: relative;
  text-align: center;
  margin: 12rem;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const CardDivisorContainer = styled.div`
  width: 100%;
  margin: 1.6rem 0 1.6rem 0;
`;

export const NoFoundMessage = styled.h2`
  font: ${props => props.theme.font.middleText};
  color: ${props => props.theme.color.black};
  text-align: center;
  margin: 5rem;
`;
