import styled from 'styled-components';

interface AddServicesCardProps {
  modalOpen?: boolean;
}

export const Container = styled.div`
  width: 72.6rem;
  padding: 3.5rem 2rem;
  background-color: ${props => props.theme.color.modalGrey};
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 0.5px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20rem;
`;

export const Title = styled.p`
  font: ${props => props.theme.font.smashTitle};
  align-self: flex-start;
`;

export const AdditionOptionsContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 1.6rem;
  gap: 1.6rem;
  flex-wrap: wrap;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  gap: 1.6rem;
`;

export const Subtitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  align-self: flex-start;
  margin-top: 3.2rem;
`;

export const AddContainer = styled.div`
  align-self: flex-start;
  display: flex;
  gap: 0.8rem;
  margin-top: 2.4rem;
`;

export const AddTitle = styled.p`
  font: ${props => props.theme.font.mediumText};
  color: ${props => props.theme.color.blue};
  cursor: pointer;
`;

export const ButtonsContainer = styled.div<AddServicesCardProps>`
  margin-top: ${props => (props.modalOpen ? '4.4rem' : '6rem')};
  display: flex;
  align-self: center;
  gap: 1.5rem;
`;

export const DenyModalButton = styled.button`
  height: 5rem;
  width: 29rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font: ${props => props.theme.font.featuredNormalText};
  border: none;
  background-color: ${props => props.theme.color.mainBrown};
  color: ${props => props.theme.color.white};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
  }
`;

export const CancelModalButton = styled.button`
  height: 5rem;
  width: 29rem;
  padding: 1rem 1.5rem;
  boheight: 5rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  border: 1px solid ${props => props.theme.color.mainBrown};
  font: ${props => props.theme.font.featuredNormalText};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.mainBrown};
  &:hover {
    background-color: ${props => props.theme.color.hoverBrown};
    color: ${props => props.theme.color.white};
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  margin-top: -25rem;
`;

export const ErrorContainer = styled.div`
  display: flex;
  margin-top: 0.8rem;
  align-items: center;
  gap: 0.8rem;
  align-self: flex-start;
`;

export const ErrorMessage = styled.p`
  color: #c60000;
  font: ${props => props.theme.font.text};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
