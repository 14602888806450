import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ScrollProps
  extends React.DetailsHTMLAttributes<HTMLAttributes<HTMLDivElement>> {
  paddingRight?: string;
}

export const Container = styled.div`
  display: flex;
`;

export const Card = styled.div`
  background-color: ${props => props.theme.color.white};
  width: 33.9rem;
  height: 28.9rem;
  display: flex;
  flex-direction: column;
  margin-left: 1.9rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 1.6rem;
`;

export const Triangle = styled.div`
  position: absolute;
  align-self: flex-end;
  margin-bottom: 2rem;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;

  border-bottom: 13px solid white;
  transform: rotate(-90deg);
  -webkit-filter: drop-shadow(-2px -1.5px 1px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(-2px -1.5px 1px rgba(0, 0, 0, 0.25));
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.1rem;
    background: ${props => props.theme.color.gray};
  }

  padding-right: ${(props: ScrollProps) =>
    props.paddingRight ? props.paddingRight : '0rem'};
`;

export const OptionsContainer = styled.div`
  display: flex;
  min-height: 4.7rem;
  align-items: center;
  justify-content: space-between;
`;

export const TitleAndImageContainer = styled.div`
  display: flex;
`;

export const OptionImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OptionImage = styled.img`
  width: 6rem;
  height: 3rem;
  object-fit: contain;
`;

export const OptionTitle = styled.h3`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
  line-height: 2rem;
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
`;

export const EmptyList = styled.p`
  font: ${props => props.theme.font.featuredText};
  color: ${props => props.theme.color.black};
  line-height: 2rem;
  position: relative;
  text-align: center;
  margin-top: 1.6rem;
`;
