import styled from 'styled-components';

export const DoctorPatientsContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  align-self: flex-start;
  background-color: none;
  width: 100%;
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme.color.whiteHover};
  }
  transition: background-color 0.1s ease-in;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PatientName = styled.p`
  font: normal normal bold 2.1rem 'Roboto', sans-serif;
  color: ${props => props.theme.color.blue};
`;

export const ForwardingContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ForwardedText = styled.p`
  font: ${props => props.theme.font.normalText};
  color: #4c4c4c;
`;

export const PatientForwardedBy = styled.p`
  font: ${props => props.theme.font.featuredNormalText};
  color: #4c4c4c;
  margin-left: 5px;
`;
